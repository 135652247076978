import React, { useEffect } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import {
  getConversationGroup,
  fetchConversationList,
} from "../../store/service";
import Header from "../../components/Header";

import ConversationItem from "./ConversationItem";
import useStyles from "./styles";

const ConversationList = (props) => {
  const classes = useStyles();

  const { lastFetched, data, fetchMyConversationList } = props;

  const conversations =
    lastFetched && data.records.filter((c) => c.initiated_by.agent);

  useEffect(() => {
    fetchMyConversationList();
  }, []);

  const showConversation = (conversationKey) => {
    if (props.onShowConversation) {
      props.onShowConversation(conversationKey);
    }
  };

  return (
    <>
      <Header
        title="Conversation History"
        mainButton
        mainButtonTitle="START NEW CONVERSATION"
        mainButtonEvent={() => showConversation(0) }
      />
      <Box className={classes.container}>
        <List>
          {conversations.length > 0 &&
            conversations
              .map((item) => (
                <ConversationItem
                  item={item}
                  onClick={() => showConversation(item.uid)}
                />
              ))
              .reduce((p, n) => (p ? [p, <Divider />, n] : n), null)}
        </List>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...getConversationGroup(state, "me"),
    user: state.service.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchMyConversationList: (groupKey) => {
      dispatch(fetchConversationList("me", true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);
