import {
  GET_ACTIVITY_HISTORY_ERROR,
  GET_ACTIVITY_HISTORY_PENDING,
  GET_ACTIVITY_HISTORY_PENDING_LAZY_LOAD,
  GET_ACTIVITY_HISTORY_SUCCESS,
  RESET_PARAMS,
} from "../reducers/activityHistory";

export const getActivityHistoryPending = () => ({
  type: GET_ACTIVITY_HISTORY_PENDING,
});
export const getActivityHistoryPendingLazyLoad = () => ({
  type: GET_ACTIVITY_HISTORY_PENDING_LAZY_LOAD,
});
export const getActivityHistoryError = (e) => ({
  type: GET_ACTIVITY_HISTORY_ERROR,
  payload: {
    error: e,
  },
});
export const getActivityHistorySuccess = (data) => ({
  type: GET_ACTIVITY_HISTORY_SUCCESS,
  payload: {
    data,
  },
});

export const resetParams = () => ({
  type: RESET_PARAMS,
});
