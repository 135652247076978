import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  fetchSalesList,
  getSalesList,
} from "../../store/service/reducers/sales";
import Header from "../../components/Header";

import useStyles from "./styles";
import classNames from "classnames";

const SalesList = ({ setActiveView, activeView }) => {
  const [selected, setSelected] = useState("");
  const [scroll, setOnScroll] = useState(false);
  const [queryString, setQueryString] = useState({
    q: null,
    size: 25,
    offset: 0,
  });
  const sales = useSelector((state) => getSalesList(state));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const dateNow = moment();

  const { isFetching, data, isFetchingLazyLoad } = sales;
  const { records } = data;

  useEffect(() => {
    const newQueryString = queryString;
    !newQueryString.q && delete newQueryString.q;
    dispatch(fetchSalesList(true, null, true));
  }, [activeView]);

  useEffect(() => {
    console.log(sales);
  }, []);

  const handleSelected = (uid) => {
    setSelected((prevState) => (prevState === uid ? "" : uid));
    history.push(`/sales/${uid}`);
  };

  const onSearch = (e) => {
    setQueryString({ ...queryString, q: e.target.value, offset: 0 });
    const q = e.target.value;
    if (q.length >= 3) {
      setTimeout(() => {
        dispatch(fetchSalesList(true, { q }, true, false, true));
      }, 500);
      return;
    }
    if (!q || q.length < 1) {
      const newQueryString = queryString;
      delete newQueryString.q;
      dispatch(fetchSalesList(true, null, true, false, false));
      return;
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0) {
      if (scroll === false) {
        setOnScroll(true);
      }
    } else {
      setOnScroll(false);
    }
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (data && data.records.length > 0) {
        const newQueryString = queryString;
        !newQueryString.q && delete newQueryString.q;
        if (!isFetchingLazyLoad && records.length < data.total) {
          dispatch(fetchSalesList(true, null, false, true));
        }
        return;
      }
    }
  };

  return (
    <>
      <Header
        title="Wish List"
        searchInput
        onChangeSearchInput={onSearch}
        contentScroll={scroll}
      />
      <Box className={classes.container} onScroll={handleScroll}>
        {!data || isFetching ? (
          <CircularProgress className={classes.loadingIndicator} />
        ) : (
          <List className={classes.salesList}>
            {records.map((item, index) => (
              <>
                <ListItem
                  key={index}
                  onClick={() => handleSelected(item.uid)}
                  className={`${classes.listItem} ${
                    selected === item.uid ? classes.listItemSelected : ""
                  }`}
                >
                  <ListItemText
                    className={`${
                      selected === item.uid ? classes.selectedListItem : ""
                    }`}
                    primary={
                      <>
                        <Box display="flex" alignItems="baseline">
                          <Box flexGrow={1}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 700 }}
                              color="textPrimary"
                            >
                              {item.name}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              noWrap={true}
                            >
                            {moment(item.updated_at).fromNow(true)}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    }
                    secondary={
                      <Box className={classes.wrapperSecondaryListItem}>
                        <Box className={classes.secondaryListItem}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            noWrap={false}
                            gutterBottom
                          >
                            {item.key} /&nbsp;
                            {item.primary_contact &&
                              <>
                                {item.primary_contact.first_name} {item.primary_contact.last_name}                              
                              </>
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" noWrap={false} component="span" className={classes.statusItem}>
                            {item.status.name}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    primary={
                      <>
                        <Box display="flex" alignItems="baseline">
                          <Box flexGrow={1}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 700 }}
                              color="textPrimary"
                            >
                              {item.primary_contact?.first_name} {item.primary_contact?.last_name}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              noWrap={true}
                            >
                            {moment(item.updated_at).fromNow(true)}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    }
                    secondary={
                      <Box className={classes.wrapperSecondaryListItem}>
                        <Box className={classes.secondaryListItem}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            noWrap={false}
                            gutterBottom
                          >
                            {item.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" noWrap={false} component="span" 
                            className={classNames(classes.statusItem, classes[`statusItem_${item.status.key.replace("-", "_")}`])}
                            >
                            {item.status.name}
                          </Typography>
                        </Box>
                      </Box>
                    }                    
                  />
                </ListItem>
                <Divider light />
              </>
            ))}
          </List>
        )}
        {isFetchingLazyLoad && (
          <CircularProgress
            className={`${classes.loadingIndicator} ${classes.loadingIndicatorBottom}`}
          />
        )}
      </Box>
    </>
  );
};

export default SalesList;
