import React, { useState, useEffect, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Route,
  Link as RouterLink,
  withRouter,
  useLocation,
} from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import { 
  getContentRecord, 
  fetchContentRecord,
  logSessionEvent,  
} from "../../store/service";

import Page from "./Page";

const styles = (theme) => ({
  root: {
    //    maxWidth: 480,
    //    display: 'flex',
    width: "100%",
    minHeight: "100vh",
  },
  content: {},
  divider: {
    width: "100%",
    height: theme.spacing.unit,
    //    backgroundColor: theme.palette.common.white,
    backgroundColor: "#eee",
  },
});

const FeaturePage = ({
  classes,
  history,
  openLink,
  isFetching,
  lastFetched,
  data,
  logEvent,
  fetchFeatureDetail,
  featureKey,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    fetchFeatureDetail()
    if (lastFetched)
      logEvent('view', { 
          type: 'content',
          model: 'feature',
          uid: data.uid, 
          url: window.location.href,
          title: `${data.title || data.name}`,
      })          
  }, [featureKey, lastFetched]);

  useEffect(() => {
    fetchFeatureDetail(true);
  }, [pathname]);

  const renderText = (text, context) => {
    if (!text || !context) {
      return "";
    }

    return text.replace(/\{\{(.*?)\}\}/gi, (m, p1) => {
      const name = p1.trim();
      if (name in context) {
        return context[name];
      } else {
        return "";
      }
    });
  };

  const renderSections = (props) => {
    const { createSection, createContentBlock, divider, pageData } = props;

    const sectionsData = data.sections?.map(s_data => {
      let s = pageData.sections.find(section => section.key == (s_data.section_type?.value || 'gallery'))
      if (!s)
        s = pageData.sections[0]

      // Clone
      s = JSON.parse(JSON.stringify(s))

      if (s.key === "main")
        return {
          ...s,
          title: s_data.hide_title ? null : renderText(s.title, s_data),
          subtitle: renderText(s.subtitle, s_data),
          body: renderText(s.body, s_data),
          images: s_data.images,
        }
      else
        return {
          ...s,
          title: s_data.hide_title ? null : renderText(s.title, s_data),
          subtitle: renderText(s.subtitle, s_data),
          body: renderText(s.body, s_data),
          items: s_data.images?.map((i) => {
            let title = null
            if (s_data.show_image_name) {
              title = i.title
              if (!title) {
                title = i.file_name
                // Hide extension
                if (title && title.indexOf(".") >= 0)
                  title = title.split('.').slice(0, -1).join('.')
              }
            }

            return {
              title,
              images: [i]
            }
          }) || [],        
        };
    }) || [];

    if (!sectionsData?.length)
        return []

    const sections = sectionsData
      .map((sectionData, index) => createSection(sectionData, index))
      // Divider
      .reduce((prev, next) => [prev, divider, next]);

    return sections;
  };

  const renderHelmet = ({ pageData }) => {
    let title = "Feature Detail";
    if (lastFetched) {
      title = data.title || data.name;
    }

    return (
      <title>
        {title} - {process.env.REACT_APP_TITLE_SUFFIX}
      </title>
    );
  };

  if (isFetching) {
    return (
      <Box style={{ width: "100%", height: "100vh", backgroundColor: "white" }}>
        <Typography>Loading</Typography>
      </Box>
    );
  } else {
    return (
      <Page
        pageKey="feature-detail"
        renderSections={renderSections}
        renderHelmet={renderHelmet}
        featureKey={featureKey}
        doNotLogEvent={true}
      />
    );
  }
};

function featureKeyFromProps(props) {
  let key = null;
  if (props.featureKey) {
    key = props.featureKey;
  } else if (props.match) {
    key = props.match.params.featureKey;
  }
  return key;
}

const mapStateToProps = (state, ownProps) => {
  const key = featureKeyFromProps(ownProps);
  return {
    ...getContentRecord(state, "feature", key),
    featureKey: key,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const key = featureKeyFromProps(ownProps)
  // Fix search is null if there's hash  
  let urlParams = new URLSearchParams(ownProps.location.search)
  if (ownProps.location.hash && ownProps.location.hash.indexOf('?') != -1)  
    urlParams = new URLSearchParams(ownProps.location.hash.substring(
      ownProps.location.hash.indexOf('?')))
  
  return {
    fetchFeatureDetail: (refresh) => {
      dispatch(fetchContentRecord("feature", key, refresh))
    },
    logEvent: (eventName, eventParam) => {
      // Get session id param if any
      const sessionUid = urlParams.get("session")
      if (sessionUid)
        dispatch(logSessionEvent(sessionUid, eventName, eventParam))
    },   
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(FeaturePage)
  )
);
