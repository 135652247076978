import { API } from "../../../config";
import {
  getActivityHistoryError,
  getActivityHistoryPending,
  getActivityHistoryPendingLazyLoad,
  getActivityHistorySuccess,
} from "../actions/activityHistory";
import { resetParams } from "../reducers/contact";

export const fetchActivityHistory = (
  lazyload = false,
  params = false,
  invalidate = false,
  search = false
) => async (dispatch, getState) => {
  try {
    const { user, activityHistory } = getState().service;
    let endpointUrl = `${API.urlGetActivityHistory}?offset=${
      invalidate ? "0" : activityHistory.params.offset
    }&size=${activityHistory.params.size}&`;

    if (params) {
      for (const [key, value] of Object.entries(params)) {
        endpointUrl += `${key}=${value}&`;
      }
    }

    console.log(" ini endpoint url ", endpointUrl);

    if (invalidate) {
      dispatch(resetParams());
    }

    if (!activityHistory.data) {
      dispatch(getActivityHistoryPending());
    }
    if (lazyload) {
      dispatch(getActivityHistoryPendingLazyLoad());
    }
    const res = await fetch(endpointUrl, {
      headers: {
        Authorization: `jwt ${user.authToken}`,
        "X-Service-Key": user.userInfo.business_uid,
      },
    });
    const json = await res.json();
    console.log(" ini json ", json);
    let newDataRecord = activityHistory;
    if (!json.records.length > 0) {
      dispatch(getActivityHistorySuccess(newDataRecord));
      return;
    }
    if (!invalidate) {
      if (!search) {
        newDataRecord = newDataRecord.data
          ? {
              ...activityHistory.data,
              records: [...activityHistory.data.records, ...json.records],
            }
          : json;
      } else {
        newDataRecord = json;
      }
    } else {
      newDataRecord = json;
    }

    dispatch(getActivityHistorySuccess(newDataRecord));
  } catch (e) {
    dispatch(getActivityHistoryError(e));
  }
};
