import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root:{
        // height: '100vh',
        // [theme.breakpoints.down('xs')]:{
        //     height: '30vh'
        // }
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    topPadding: {
      // height: 100,
    },
    container: {
      // top: 100,
      // bottom: 100,
      position: 'relative',
      height: '100%',
      width: '100%',
    },
    iframeYoutube:{
      position:'absolute',
      top:0,
      left:0,
      bottom:0,
      right:0,
      width:'100%',
      height:'100%',
      border:'none',
      margin:0,
      padding:0,
      overflow:'hidden',
      zIndex:999999,
      // top: 100,
      // bottom: 100,
      // height: '60%',
    }
})


const VideoPlayerPage = ({ classes, location }) => {

  const urlParams = new URLSearchParams(location.search);
  const url = urlParams.get("url")

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }  

  let videoUrl = url
  const youTubeId = getId(url)
  if (youTubeId)
    videoUrl = `https://www.youtube.com/embed/${youTubeId}`

  return (
    <div className={classes.root}>
      <div className={classes.topPadding} />
      <div className={classes.container}>
        <iframe className={classes.iframeYoutube} src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(VideoPlayerPage));
