import { renderToString } from "react-dom/server";
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import { Chat } from "@material-ui/icons";
import ResizeDetector, { withResizeDetector } from "react-resize-detector";

import {
  getConversation,
  fetchConversation,
  fetchMessages,
  sendMessage,
  markReadAll,
  startTyping,
  WSSTATE,
  getConnectionState,
  getBusinessInfo,
  fetchBusinessInfo,
  getUser,
  wsSend,
  logSessionEvent
} from "../../store/service";
import { contentTheme } from "../../config/theme";
import { fetchContactList } from "../../store/service/reducers/contact";
import Header from "../../components/Header";

import MessageList, { parseBody } from "./MessageList";
import ComposeBar from "./ComposeBar";
import HeaderView from "./HeaderView";
import VideoViewer from "./VideoViewer";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    //overflowY: "scroll",
    //overflowX: "hidden",
    //paddingBottom: theme.spacing(1),
    overflow: "hidden",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  root: {
    //    backgroundColor: theme.palette.background.paper
  },
  header: {
    zIndex: 1300,
    position: "sticky",
    top: "0",
  },
  footer: {
    display: "block",
    position: "sticky",
    bottom: "0",
  },
  chatInfo: {
    color: "#999",
  },
  inline: {
    display: "inline",
  },
  displayNone: {
    display: "none",
  },

  muted: {
    color: "#999",
  },
}));

const ConversationView = (props) => {
  /// console-.log('debug --- parent ConversationView')
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [scroll, setOnScroll] = useState(false);
  const [viewVideoUrl, setViewVideoUrl] = useState(null)

  const {
    business,
    user,
    conversationKey,
    conversation,
    fetchMessages,
    sendMessage,
    markReadAll,
    startTyping,
    getContactList,
    onHide,
    connectionState,
    headerComponent = HeaderView,
    renderHeader,
    setActiveView,
    width = 200,
    height = 100,
  } = props;

  useEffect(() => {
    let timeoutID = null;
    if (conversation && conversation.data.new_count > 0) {
      timeoutID = setTimeout(() => {
        markReadAll();
      }, 50);
    }

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [conversation.data.new_count]);

  const [firstFetch, setFirstFetch] = useState(true);

  useEffect(() => {
    // Refetch on first open or when connected
    if (firstFetch || connectionState == WSSTATE.Connected) {
      fetchMessages();
      if (firstFetch) {
        setFirstFetch(false);
      }
    }
  }, [connectionState, conversationKey]);

  const checkOnlineAndSendMessage = (message, files = null) => {
    if (connectionState != WSSTATE.Connected) {
      alert(
        "Can not send message because you are not online. Please check your internet connection and try again!"
      );
      return;
    }

    sendMessage(message, files);
  };

  const openLink = (linkTo, target=null, linkTitle=null) => {
    if (linkTo.startsWith("mailto:")) {
      window.location.href = linkTo
      return
    }
    if (linkTo.startsWith("http") && (
        linkTo.indexOf("//youtube.com") > 0 ||
        linkTo.indexOf("//www.youtube.com") > 0 ||
        linkTo.indexOf("//m.youtube.com") > 0 ||
        linkTo.indexOf("//youtu.be") > 0
      )) {
      props.logUrlEvent(linkTo, linkTitle)
      history.push(`/video?url=${linkTo}`)
      // setViewVideoUrl(linkTo)
      return
    }

    if (linkTo.startsWith("http")) {
      props.logUrlEvent(linkTo, linkTitle)      
      window.open(linkTo, target || "_blank")
      return
    }

    let link = "";
    if (linkTo.startsWith("/")) {
      link = linkTo;
    } else {
      link = "/" + linkTo;
    }

    if (link == "/home") {
      link = "/";
    }

    history.push(link);

    if (onHide) {
      onHide();
    }
  };

  let typing_at = null;
  conversation.data.parties &&
    conversation.data.parties.forEach((p) => {
      if (p.agent && p.agent.uid == user.userInfo.uid && p.typing_at) {
        typing_at = moment(p.typing_at).toDate();
      }
    });

  let composeComponent = null;
  let defaultValue = null;

  if (conversation.data.accept_reply) {
    let disableSendMessage = false;
    if (conversation.messages.length) {
      const lastMessage =
        conversation.messages[conversation.messages.length - 1];
      const content = parseBody(lastMessage.body);

      defaultValue = content.default || "";

      if (!user.userInfo.is_staff && content.disable_send_message) {
        disableSendMessage = true;
      }
    }

    if (!composeComponent) {
      composeComponent = (
        <ComposeBar
          sendMessage={checkOnlineAndSendMessage}
          defaultValue={defaultValue}
          disabled={disableSendMessage}
          onChange={() => {
            if (
              !typing_at ||
              new Date().getTime() - typing_at.getTime() >= 5000
            ) {
              startTyping();
            }
          }}
        />
      );
    }
  }

  let header = null;
  if (false && conversation && conversation.lastFetched) {
    if (renderHeader) {
      header = renderHeader(conversation);
    } else if (headerComponent) {
      header = React.createElement(headerComponent, {
        business,
        conversation,
        connectionState,
      });
    }
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0) {
      if (!scroll) {
        setOnScroll(true);
        return;
      }
      return;
    }
    console.log(e.target.scrollTop);
    setOnScroll(false);
  };
  /// console-.log('debug --- conversation', conversation)

  const MessageListMemo = useMemo(()=> {
    /// console-.log('debug --- MessageListMemo')
    return (
      <MessageList
          business={business}
          user={user}
          conversation={conversation}
          sendMessage={checkOnlineAndSendMessage}
          openLink={openLink}
          containerHeight={0}
          containerWidth={width}
        />
    )
  }, [business, user, conversation.messages])
  return (
    <>
      {/* Disabled. It causes content below header row can not be tapped.    
      <Header
        buttonRight
        buttonRightIcon={<Chat />}
        buttonRightEvent={() => setActiveView("history")}
        contentScroll={scroll}
        variant="transparent"
      />
      */}
      <div
        className={classes.container}
        onScroll={handleScroll}
        // style={{
        //   // height: height,
        //   height: "100%",
        //   position: "relative",
        // }}
      >
        {/* {header && (
        <div
          className={classes.header}
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          {header}
        </div>
      )} */}

        {MessageListMemo}

        {/* <MessageList
          business={business}
          user={user}
          conversation={conversation}
          sendMessage={checkOnlineAndSendMessage}
          openLink={openLink}
          containerHeight={0}
          containerWidth={width}
        /> */}

        {/*
        <div
          className={classes.footer}
          style={{
            position: "absolute",
            bottom: "0px", //'16px',
            width: `calc(100% - ${theme.spacing(4)}px)`,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            padding: 0,
            display: "none",
          }}
        >
          {composeComponent}
        </div>
        */}
      </div>
      {viewVideoUrl &&
        <VideoViewer open={true} url={viewVideoUrl}
          onClose={() => setViewVideoUrl(null)}
          />
      }
    </>
  );
};

function conversationKeyFromProps(props) {
  let key = 0;
  if (props.conversationKey) {
    key = props.conversationKey;
  } else if (props.match) {
    key = props.match.params.key;
  }
  return key;
}

const mapStateToProps = (state, ownProps) => {
  const key = conversationKeyFromProps(ownProps);

  return {
    conversation: getConversation(state, key),
    business: getBusinessInfo(state, "0").data,
    user: getUser(state),
    connectionState: getConnectionState(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const key = conversationKeyFromProps(ownProps);

  return {
    wsStart: () => {
      dispatch(wsSend("start", null));
    },
    fetchMessages: () => {
      dispatch(fetchMessages(key, true, false));
    },
    sendMessage: (message, files = null) => {
      dispatch(sendMessage(key, message, files));
    },
    startTyping: () => {
      dispatch(startTyping(key));
    },
    markReadAll: () => {
      dispatch(markReadAll(key));
    },
    // getContactList: () => {
    //   dispatch(fetchContactList());
    // },
    logUrlEvent: (linkTo, linkTitle) => {
      let urlParams = (new URL(linkTo)).searchParams;
      const sessionUid = urlParams.get("session")
      if (sessionUid)

        dispatch(logSessionEvent(sessionUid, 
          "view", 
          {
            type: 'page',
            url: linkTo,
            title: linkTitle,
          }))
    }
  };
};

export default withResizeDetector(
  connect(mapStateToProps, mapDispatchToProps)(ConversationView)
);