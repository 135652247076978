import { updateObject, updateItemInArray, createReducer } from './utility'

export const logSessionEvent = (sessionUid, eventName, eventData) => {
  return (dispatch, getState) => {    
    let url =
      process.env.REACT_APP_GOAPP_API_URL +
      `/visitor/service/session/${sessionUid}/log_event/`
      
    let authorization = {};
    const { user } = getState().service;
    if (user.isLoggedIn) {
      authorization = { Authorization: "jwt " + user.authToken };
    }

    const serviceKey = user.userInfo.business_uid;
    
    let data = {
      event_name: eventName,
      event_data: eventData,
    }
    
//    alert("LogEvent: " + JSON.stringify(data, null, 2))

    return fetch(url, { 
      method: 'POST',
      cache: 'no-cache',
      headers: { 
        'Content-Type': 'application/json', 
        'X-Service-Key': serviceKey,
        },
      body: JSON.stringify(data)
        })
      .then(response => response.json())
      .then(json => {
//      alert("result: " + JSON.stringify(json))
//      ls.set('_goid', json['_goid'])
//      cookie.set("onboarded", true, {path: "/"});
      })
      .catch(error => {
      })
  }
}

const sessionReducer = (state={}, action) => {
  return state
}

export default sessionReducer