import React from "react";
import {
  Route,
  Link,
  withRouter,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Login from "./views/Login";
import { withAppBase } from "./AppBase";
import NavigationController from "./navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

function App(props) {
  const { loadingStatus, user, login, logout } = props;

  const classes = useStyles(props);

  /*
  if (loadingStatus) {
    return <div className={classes.root}>{loadingStatus}</div>;
  } 
  else if (user.authError) {
    return (
      <div className={classes.root}>
        Authentication Failed! Error: {JSON.stringify(user.authError, null, 2)}
      </div>
    );
  } 
  */
  if (!user.isLoggedIn) {
    return (
      // <div className={classes.root}>
      //   You're not authenticated.{" "}
      //   <a href="#" onClick={login}>
      //     Click here to login
      //   </a>
      // </div>
      <Login auth={login} status={loadingStatus} error={user.authError} />
    );
  } else if (!user.isStaff) {
    return (
      <div className={classes.root}>
        You're not authorized.{" "}
        <a href="#" onClick={logout}>
          Log out
        </a>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <NavigationController />
    </div>
  );
}

export default withAppBase(withRouter(App));
