import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { getBusinessInfo, getUser } from "../../store/service";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ConversationItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { item, selected, onClick } = props;

  const business = useSelector((state) => getBusinessInfo(state));
  const user = useSelector((state) => getUser(state));

  const title = business.data.name;
  let sender = "";
  let messageStatus = "";
  let preview = "...";
  let date = "";
  const stat = "";

  /*
  title = item.parties.map(p => {
    if (p.contact) {
      let name = p.contact.first_name
      if (p.contact.last_name)
        name += " " + p.contact.last_name
      if (!name || name.length <= 0)
        name = "New Contact"
      return name
    }
    else
      return null
  }).reduce((p, n) => {
    if (p && n)
      return p + ", " + n
    else if (n)
      return n
    else
      return p
  })

  if (!title && item.queue)
    title = item.queue.name
  */

  //    alert(JSON.stringify(item, null, 2))
  let msg = item.last_message;
  if (item.messages && item.messages.length > 0) {
    msg = item.messages[item.messages.length - 1];
  }
  if (msg) {
    preview = msg.body;
    if (
      msg.attachments &&
      msg.attachments !== undefined &&
      msg.attachments.length > 0
    ) {
      preview = "Photo";
    }

    if (preview.startsWith("{") && preview.endsWith("}")) {
      preview = JSON.parse(preview).text;
    }

    if (preview) {
      preview = preview.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, "$1");
    }

    if (msg.isSending) {
      date = "Sending...";
    } else {
      if (msg.sent_at) {
        date = moment(msg.sent_at).fromNow(true);
      }

      if (msg.sender.agent) {
        if (msg.sender.agent.uid == user.userInfo.uid) {
          sender = "You";
        } else {
          sender = msg.sender.agent.first_name;
          if (msg.sender.last_name) {
            sender += " " + msg.sender.last_name;
          }
        }

        if (msg.read_at) {
          messageStatus = "Read";
        } else {
          messageStatus = "Not read";
        }
      }
    }
  }

  //    if (msg.agent)
  //      sender = msg.agent.first_name
  //    else if (msg.service)
  //      sender = msg.service.first_name

  date = moment(item.last_active).fromNow(true);

  return (
    <ListItem
      alignItems="flex-start"
      style={{ cursor: "default" }}
      onClick={onClick}
    >
      <ListItemText
        primary={
          <>
            <Box display="flex" alignItems="baseline">
              <Box flexGrow={1}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 700 }}
                  noWrap={true}
                  color="textPrimary"
                >
                  {title}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary" noWrap={true}>
                  {date}
                </Typography>
              </Box>
            </Box>
          </>
        }
        secondary={
          <>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="body2" color="textPrimary" noWrap={false}>
                  {sender && <> {sender}: </>}
                  {preview}
                </Typography>
              </Box>
            </Box>
          </>
        }
      />
    </ListItem>
  );
};

export default ConversationItem;
