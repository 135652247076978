import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const styles = theme => ({
    dialogVideo:{
        height: '100vh',
        [theme.breakpoints.down('xs')]:{
            height: '30vh'
        }
    },
    iframeYoutube:{
      position:'absolute',
      top:0,
      left:0,
      bottom:0,
      right:0,
      width:'100%',
      height:'100%',
      border:'none',
      margin:0,
      padding:0,
      overflow:'hidden',
      zIndex:999999
    }
})


const VideoViewer = ({ classes, url, open, onClose }) => {

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }  

  let videoUrl = url
  const youTubeId = getId(url)
  if (youTubeId)
    videoUrl = `https://www.youtube.com/embed/${youTubeId}`

  return (
    <div>
      <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={open}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
      >
          <DialogContent className={classes.dialogVideo}>
              <iframe className={classes.iframeYoutube} src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </DialogContent>
      </Dialog>
    </div>
  )
}

VideoViewer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoViewer);
