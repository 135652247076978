import { createMuiTheme } from "@material-ui/core/styles";
import { green, grey, red } from "@material-ui/core/colors";

const defaultPagePalette = {
  white: { background: "#fff", text: "#000" },
  black: { background: "#000", text: "#fff" },
  light: { background: "#eee", text: "#000" },
  dark: { background: "#333", text: "#fff" },
  accent: { background: "#ff6308", text: "#fff" },
};

const appFont = process.env.REACT_APP_FONT
  ? JSON.parse(process.env.REACT_APP_FONT)
  : { default: [] };
const appPalette = process.env.REACT_APP_PALETTE
  ? JSON.parse(process.env.REACT_APP_PALETTE)
  : {};
const pagePalette = process.env.REACT_APP_PAGE_PALETTE
  ? JSON.parse(process.env.REACT_APP_PAGE_PALETTE)
  : defaultPagePalette;

const rawTheme = createMuiTheme({
  typography: {
    fontFamily: [
      ...appFont.default,
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const contentTheme = createMuiTheme({
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    primary:
      "primary" in appPalette
        ? { main: appPalette.primary, contrastText: "#fff" }
        : rawTheme.palette.primary,
    secondary:
      "secondary" in appPalette
        ? { main: appPalette.secondary, contrastText: "#fff" }
        : rawTheme.palette.secondary,
    type: "dark",
  },
  sectionPalette: {
    ...rawTheme.pagePalette,
    white:
      "white" in pagePalette
        ? {
            background: pagePalette.white.background,
            contrastText: pagePalette.white.text,
          }
        : rawTheme.palette.primary,
    black:
      "black" in pagePalette
        ? {
            background: pagePalette.black.background,
            contrastText: pagePalette.black.text,
          }
        : rawTheme.palette.primary,
    light:
      "light" in pagePalette
        ? {
            background: pagePalette.light.background,
            contrastText: pagePalette.light.text,
          }
        : rawTheme.palette.primary,
    dark:
      "dark" in pagePalette
        ? {
            background: pagePalette.dark.background,
            contrastText: pagePalette.dark.text,
          }
        : rawTheme.palette.primary,
    accent:
      "accent" in pagePalette
        ? {
            background: pagePalette.accent.background,
            contrastText: pagePalette.accent.text,
          }
        : rawTheme.palette.secondary,
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        /*
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(6),
          paddingRight: rawTheme.spacing(6),
        },
        [rawTheme.breakpoints.up('md')]: {
          paddingLeft: rawTheme.spacing(12),
          paddingRight: rawTheme.spacing(12),
        }
        */
      },
    },
  },
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "5rem", //'6rem'
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "3rem", //'3.75rem'
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.5rem", //'3rem'
      },
    },
    h4: {
      ...rawTheme.typography.h4,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.75rem", //'2.125rem'
      },
    },
    h5: {
      ...rawTheme.typography.h5,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.25rem", // '1.5rem'
      },
    },
    h6: {
      ...rawTheme.typography.h6,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.125rem", // '1.25rem'
      },
    },
  },
});

const appTheme = createMuiTheme({
  typography: {
    fontFamily: [
      ...appFont.default,
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 13,
  },
  palette: {
    primary: {
      main: "#a6d4fa",
      main: "#ff4a5c",
      main: "rgb(223, 15, 32)"
    },
    secondary: {
      main: "#1A76D1",
    },
    // type: "dark",
    type: 'light',
  },
});

export default appTheme;
