import React, { Component, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Paper,
  IconButton,
  useMediaQuery,
  Avatar,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  Drawer,
} from "@material-ui/core";
import {
  History as HistoryIcon,
  AccountCircle as AccountIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { COLORS, IMAGES } from "../config";
import { useWindowSize } from "../utilities";
import { contentTheme } from "../config/theme";
import { logout, manageAccount } from "../store/service";
import { Page } from "../views/contents";

export const NavigationContext = React.createContext({});

const useStyles = makeStyles((theme) => ({
  root: {
    //    backgroundColor: theme.palette.background.default,
    //    width: '100%',
    //    display: 'flex',
    //    justifyContent: 'center'
    //    backgroundColor: '#f5f5f5'
    //    paddingTop: theme.spacing(3),
    // padding: theme.spacing(3),
    // paddingLeft: theme.spacing(1.5),
  },
  bottomNav: {
    width: "100%",
    position: "fixed",
    display: "flex",
    elevation: 6,
    paddingBottom: theme.spacing(1),
    justifyContent: "space-around",
    bottom: 0,
  },
  headerNav: {
    width: "100%",
    position: "fixed",
    zIndex: 99,
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    top: 0,
    height: "60px",
  },
  backgroundOverlay: {
    zIndex: -1,
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  topBar: {
    position: "fixed",
    top: 0,
    zIndex: 1100, // App Bar
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  menuButton: {
    //    marginLeft: 12,
    //    marginRight: 12,
  },
  menuButtonHidden: {
    display: "none",
  },
  linearProgress: {
    position: "fixed",
    height: 2,
    overflow: "hidden",
    width: "100%",
    zIndex: 1250, // Above Drawer (1200)
    top: 0,
  },
  chatView: {
    // overflowX: "hidden",
    // overflowY: "scroll",
    // paddingBottom: theme.spacing(4),
    //height: "100vh",  
    overflow: "auto",
  },
  title: {
    flexGrow: 1,
    fontWeight: 800,
    paddingLeft: theme.spacing.unit * 2,
  },
  appBarSpacer: theme.mixins.toolbar,
  bottomBarSpacer: {
    height: theme.mixins.toolbar.height,
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    width: "100%",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  bottomNavBarMenu: {
    width: "100%",
    position: "fixed",
    bottom: 60 + 8,
    textAlign: "center",
    "z-index": 1050, // Below appbar (1100)
  },
  bottomNavBar: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  sideContent: {
    width: 400,
    //    paddingTop: theme.spacing(4)
  },
  sideContentCloseButton: {
    position: "fixed",
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    "z-index": 1350,
  },
  popupContentCloseButton: {
    position: "fixed",
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    "z-index": 1350,
  },
  poweredBy: {
    color: "#aaa",
    fontSize: 12,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));

// Help scroll to top on page change

const NavigationView = (props) => {
  const [windowWidth, windowHeight] = useWindowSize();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const contentPath = pathname.split("/");
  const isContentPath = pathname != "" && pathname != "/"
  /*
  const isContentPath =
    contentPath.includes("content") ||
    contentPath.includes("cluster") ||
    contentPath.includes("sales") ||
    contentPath.includes("contact");
  */

  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    main,
    menuButtons,
    showContent,
    setActiveView,
    showMenu,
    menuAnchorEl,
    hideMenu,
    business,
    activeView,
  } = props;

  // const configuredMenuButtons = menuButtons.splice(4, 1);

  let configuredMenuButtons = []
  configuredMenuButtons.push(menuButtons[2])
  configuredMenuButtons.push(menuButtons[0])
  configuredMenuButtons.push(menuButtons[3])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeView]);

  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }
  };

  if (matchesSmDown) {
    return (
      <Box style={{ width: "100%", height: "100%", overflowY: "hidden" }}>
        <Drawer
          anchor="bottom"
          open={isContentPath}
          onClose={() => history.replace("/")}
        >
          <Box style={{ height: "100vh" }}>
            <ThemeProvider theme={contentTheme}>
              <IconButton
                onClick={() => history.replace("/")}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  zIndex: 1000000,
                }}
              >
                <CancelIcon />
              </IconButton>
              <Paper
                elevation={12}
                style={{
                  overflowX: "hidden",
                  overflow: "scroll",
                  minHeight: "100vh",
                }}
              >
                {props.children}
              </Paper>
            </ThemeProvider>
          </Box>
        </Drawer>
        <Box className={classes.chatView} mb={12}>{main}</Box> {/* CHAT VIEW */}
        <Paper className={classes.bottomNav}>{configuredMenuButtons}</Paper>
        {/* BOTTOM NAV */}
      </Box>
    );
  }

  return (
    <div
      className={classes.root}
      maxWidth="xl"
      style={{
        height: `${windowHeight}px`,
        position: "fixed",
        width: "100%",
        //        top: 0
      }}
    >
      <Box display="flex" style={{ height: "100%" }}>
        <Box
          width={theme.spacing(7)}
          display="flex"
          flexDirection="column"
          flexShrink={0}
          alignItems="center"
          ml={1}
          mt={1}
          mb={1}
        >
          {menuButtons &&
            menuButtons.reduce(
              (p, n) => (p ? [p, <Box height={theme.spacing(1)} />, n] : [n]),
              null
            )}
        </Box>
        <Box
          width="32%"
          flexShrink={0}
          className={classes.chatView}
        >
          {main}
        </Box>
        <Box flexGrow={1} style={{ 
          opacity: 1.0, 
          // Note: Must add fixed with, otherwise some content doesnt' work such as
          // horizontal scrolling gallery
          width: `calc(68% - ${theme.spacing(7)}px)` 
          }}>
          <Box height="0px" />
          {true && (
            <ThemeProvider theme={contentTheme}>
              <Paper
                elevation={4}
                rounded
                style={{
                  height: "calc(100% - 0px)",
                  overflow: "auto"
                }}
                onScroll={handleScroll}
              >
                { window.location.pathname && window.location.pathname !== "/" &&
                  <div style={{
                      position: "absolute",
                      right: "24px",
                      top: "24px",
                      width: "48px",
                      height: "48px",
                      zIndex: 1000000,
                      borderRadius: 24,
                      backgroundColor: "rgba(255,255,255,0.9)",
                    }}>
                    <IconButton
                      onClick={() => history.replace("/")}
                      style={{
                        // position: "absolute",
                        // right: "24px",
                        // top: "24px",
                        // zIndex: 1000000,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                }

                {props.children}
              </Paper>
            </ThemeProvider>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default NavigationView;
