export const GET_ACTIVITY_HISTORY_PENDING = "GET_ACTIVITY_HISTORY_PENDING";
export const GET_ACTIVITY_HISTORY_SUCCESS = "GET_ACTIVITY_HISTORY_SUCCESS";
export const GET_ACTIVITY_HISTORY_ERROR = "GET_ACTIVITY_HISTORY_ERROR";
export const GET_ACTIVITY_HISTORY_PENDING_LAZY_LOAD =
  "GET_ACTIVITY_HISTORY_PENDING_LAZY_LOAD";

export const RESET_PARAMS = "RESET_PARAMS";

const initialState = {
  isFetching: false,
  isFetchingLazyLoad: false,
  offset: 0,
  params: { size: 25, offset: 0 },
  data: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ACTIVITY_HISTORY_PENDING_LAZY_LOAD:
      return { ...state, isFetchingLazyLoad: true };
    case GET_ACTIVITY_HISTORY_PENDING:
      return { ...state, isFetching: true };
    case GET_ACTIVITY_HISTORY_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
        isFetchingLazyLoad: false,
        params: {
          ...state.params,
          offset: state.params.offset + state.params.size,
        },
      };
    case GET_ACTIVITY_HISTORY_ERROR:
      return {
        ...state,
        ...payload,
        isFetching: false,
        isFetchingLazyLoad: false,
      };

    case RESET_PARAMS:
      return { ...state, params: { size: 25, offset: 0 } };

    default:
      return state;
  }
};
