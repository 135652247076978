/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Input,
  LinearProgress,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  ExpandMore as IconExpandMore,
  BusinessCenter as IconBusinessCenter,
} from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import { COLORS } from "../../../config";
import {
  fetchSalesRecord,
  getSalesRecord,
} from "../../../store/service/reducers/sales";
import ActionForm from "./ActionForm"

import useStyles from "./styles";

const SalesDetail = () => {
  const [selectedContent, setSelectedContent] = useState("tab1");
  const [expanded, setExpanded] = useState(true);
  const [actionMenuEl, setActionMenuEl] = useState(null)
  const [currentAction, setCurrentAction] = useState(null)
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const { salesId } = useParams();
  const salesDetail = useSelector((state) =>
    getSalesRecord(state, salesId)
  );
  const dateNow = moment();

  useEffect(() => {
    dispatch(fetchSalesRecord(salesId, true));
  }, [salesId]);

  const handleScroll = (e) => {
    console.log("scroll");
  };

  if (!salesDetail || (salesDetail && salesDetail.isFetching)) {
    return (
      <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
      />
    );
  }

  
  const showActionMenu = event => {
    setActionMenuEl(event.currentTarget);
  }

  const hideActionMenu = () => {
    setActionMenuEl(null);
  }  
  
  const handleAction = action => () => {
    setCurrentAction(action)
    hideActionMenu()
  }
  
  return (
    <Box className={classes.containerSalesDetail} onScroll={handleScroll}>
      {salesDetail.isUpdating && <LinearProgress />}
      <Box className={classes.wrapper}>
        <Box className={classes.wrapperTitleName}>
          <Typography className={classes.salesName}>
            <strong>
            {salesDetail.data.primary_contact?.first_name} {salesDetail.data.primary_contact?.last_name}
            </strong>
            <br/>
            {salesDetail.data.unit?.name}
          </Typography>
        </Box>
        
        <Box className={classes.wrapperPersonalInfo}>
          <Accordion
            className={classes.accordionParent}
            expanded={expanded}
          >
            {/*
            <AccordionSummary
              expandIcon={<IconExpandMore />}
              onClick={() => setExpanded(!expanded)}
            >
              <Typography>Sales Deal Details</Typography>
            </AccordionSummary>
            */}
            <AccordionDetails className={classes.accordionDetails}>
              {/*
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Deal ID
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.key}
                </Typography>
              </Box>
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Nama Unit
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.name}
                </Typography>
              </Box>
              */}
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Contact
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.primary_contact?.first_name} {salesDetail.data.primary_contact?.last_name}
                </Typography>
              </Box>
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Mobile No
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.primary_contact?.mobile_no}
                </Typography>
              </Box>
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Email
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.primary_contact?.email}
                </Typography>
              </Box>

              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel} gutterBottom>
                  Status Pemesanan
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography 
                      className={classNames(classes.statusItem, classes[`statusItem_${salesDetail.data.status.key.replace("-", "_")}`])}                      
                      component="span">
                      {salesDetail.data.status.name}
                    </Typography>
                  </Box>
                  <Box ml={1}>
                    <Button color="primary" onClick={showActionMenu}>Change Status</Button>
                    
                    <Menu
                      id={actionMenuEl ? 'action-menu-popover' : undefined}
                      open={Boolean(actionMenuEl) }
                      anchorEl={actionMenuEl}
                      getContentAnchorEl={null}
                      onClose={hideActionMenu}
                      keepMounted
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}        
                      >
                      { salesDetail.data.actions.map(action => (
                          <MenuItem onClick={handleAction(action)}>
                            {action.name}
                          </MenuItem>
                        ))
                      }
                    </Menu>                  
                  </Box>
                  
                </Box>
              </Box>
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Tanggal Status Pemesanan
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {moment(salesDetail.data.updated_at).format("DD MMM YYYY, HH:mm")}
                </Typography>
              </Box>              
              {salesDetail.data.description &&
                <Box className={classes.accordionItem}>
                  <Typography className={classes.accordionItemLabel}>
                    Description
                  </Typography>
                  <Typography className={classes.accordionItemTitle}>
                    {salesDetail.data.description}
                  </Typography>
                </Box>
              }
              {salesDetail.data.status.key === "won" &&
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Nomor SO
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.nomor_so}
                </Typography>
              </Box>
              }
              {salesDetail.data.status.key === "drop" &&
              <Box className={classes.accordionItem}>
                <Typography className={classes.accordionItemLabel}>
                  Drop Reason
                </Typography>
                <Typography className={classes.accordionItemTitle}>
                  {salesDetail.data.drop_reason}
                </Typography>
              </Box>
              }                           
            </AccordionDetails>
          </Accordion>
        </Box>        
      </Box>
      { currentAction &&
        <ActionForm 
          salesKey={salesId}
          action={currentAction}
          open={true} 
          onClose={()=>setCurrentAction(null)}
          />
      }
    </Box>
  );
};

export default SalesDetail