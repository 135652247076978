import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Reveal from "react-reveal/Zoom";

const styles = (theme) => ({
  root: {},
  button: {
    textTransform: "none",
    fontSize: theme.typography.body1.fontSize,
    // backgroundColor: "white",
  },
});

const OptionMenu = ({
  theme,
  classes,
  content,
  sendMessage,
  openLink,
  animated,
}) => {
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box mt={1} ml={3}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width="100%"
        flexWrap="wrap"
        ml={1}
        mr={1}
      >
        {content.options.map((option) => (
          <Box mt={1} mr={1}>
            <Reveal duration={animated ? 500 : 0}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={option.link_to ? ()=>openLink(option.link_to, option.target) : () =>
                  sendMessage(
                    JSON.stringify({ value: option.value, text: option.text })
                  )
                }
              >
                {option.text}
              </Button>
            </Reveal>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(OptionMenu);
