import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useContext,
  useCallback,
  Suspense,
} from "react";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import ReactLoading from "react-loading";
import Reveal from "react-reveal/Zoom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ResizeDetector, { withResizeDetector } from "react-resize-detector";

import ChatMessage from "./ChatMessage";
import MessageItem from "./MessageItem";

const styles = (theme) => ({
  root: {
    //    width: '100%',
    //    maxWidth: 500,''
    padding: theme.spacing.unit * 2,
    paddingBottom: 0,
  },

  messageTop: {
    //    minHeight: '70vh',
    //    [theme.breakpoints.up('sm')]: {
    //      minHeight: '80vh'
    //    }

    minHeight: "50vh",
    [theme.breakpoints.up("sm")]: {
      //      minHeight: '30vh'
      minHeight: "40vh",
    },
  },
  muted: {
    color: "#999",
  },
});

export const parseBody = (body) => {
  if (body && body.startsWith("{") && body.endsWith("}")) {
    const data = JSON.parse(body);
    if (data.input_type) {
      data.componentType = data.input_type + "_input";
      data.isInputComponent = true;
    } else if (data.card_list) {
      data.componentType = "card_list";
    } else if (data.card) {
      data.componentType = "card";
    } else {
      data.componentType = null;
    }

    return data;
  } else {
    return {
      text: body || "",
    };
  }
};

const groupMessages = (messages, count) => {
  let groupedMessages = [];
  if (messages.length > 0) {
    groupedMessages = messages.slice(Math.max(messages.length - count, 0))
    .reduce((p, n) => {
      n.content = parseBody(n.data.body);
      n.next = null; // reset

      if (p.length > 0 && !n.isSending) {
        let lastItem = p[p.length - 1];
        while (lastItem.next) {
          lastItem = lastItem.next;
        }
        const sent_interval = moment(n.data.created_at).diff(
          moment(lastItem.data.created_at)
        );
        if (!lastItem.isSending) {
          if (
            (n.data.sender.agent &&
              lastItem.data.sender.agent &&
              n.data.sender.agent.uid == lastItem.data.sender.agent.uid) ||
            (n.data.sender.contact && lastItem.data.sender.contact)
          ) {
            if (!lastItem.content.componentType && sent_interval <= 30000) {
              lastItem.next = n;
              return p;
            }
          }
        }
      }

      return [...p, n];
    }, []);
  }

  return groupedMessages;
};

const MessageList = ({
  classes,
  theme,
  business,
  user,
  conversation,
  initialMessage,
  containerWidth = 0,
  containerHeight = 0,
  ...props
}) => {
  /// console-.log('debug --- child 1 MessageList')
  const actionProps = {
    openLink: props.openLink,
    sendMessage: props.sendMessage,
  };

  const bottomDiv = useRef();
  const listDiv = useRef();

  const [topPadding, setTopPadding] = useState(-1);
  
  // Show only last 20 messages to improve rendering
  const [displayCount, setDisplayCount] = useState(20)

  const scrollToBottom = () => {
    if (bottomDiv.current) {
      //    alert("Scroll to bottom")
      //listDiv.current.scrollTop = listDiv.current.scrollHeight;
      bottomDiv.current.scrollIntoView();
    }
    //if (listDiv.current)
    //  listDiv.current.scrollTop = listDiv.current.scrollHeight;
  };

  // useEffect(() => {
    // scrollToBottom()
  // }, [listDiv]);

  const parties = conversation.data.parties || [];
  const groupedMessages = groupMessages([
    ...conversation.messages,
    ...conversation.pendingPosts,
  ], displayCount);

  let typingAt = 0;
  let typingCount = 0;
  const now = new Date();
  parties.forEach((p) => {
    if (
      (user.userInfo.is_staff && p.agent && p.agent.uid == user.userInfo.uid) ||
      (!user.userInfo.is_staff && p.contact)
    ) {
      return;
    }

    if (p.typing_at) {
      const date = moment(p.typing_at).toDate();
      if (now - date <= 30000) {
        if (date > typingAt) {
          typingAt = date;
        }
        typingCount += 1;
      }
    }
  });

  const ChatMessageMemo = useMemo (()=> {
    /// console-.log('debug --- ChatMessageMemo')
    return (
      <ChatMessage
        side="left"
        messages={[
          <Box style={{ height: 32, position: "relative", top: -30 }}>
            <ReactLoading
              type="bubbles"
              color="#aaa"
              width={64}
              height={64}
            />
          </Box>,
        ]}
      />
    )
  }, [])

  // Scroll to bottom when message is added or typing parties changed
  const messagesLength =
    (conversation.messages.length +
      conversation.pendingPosts.length +
      typingCount) <<
    8;
    
  const businessAvatar =
    business.avatar == undefined ? null : business.avatar.image_url;

  // Start timer to handle typingAt expiry
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (typingAt && !typingTimeout) {
      const interval = now - typingAt;
      if (interval < 30000) {
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
        setTypingTimeout(
          setTimeout(() => {
            //alert('timeout')
            setTypingTimeout(null);
          }, 30000 - interval)
        );
      }
    }
    /// console-.log('debug --- useEffect [typingAt]')
  }, [typingAt]);

  useEffect(() => {
    // 12 Nov 2021: This solve issue when data already exist on load. Strangely, this issue
    // happen only on mobile view when you switch to other page and back to conversation page
    setTimeout(scrollToBottom, 0)
    
    // scrollToBottom()
    /// console-.log('debug --- useEffect [messagesLength, topPadding, conversation.key, typingAt]')
  }, [messagesLength, topPadding, conversation.key, typingAt]);
  

  let containerStyle = {};
  if (containerHeight > 0) {
    containerStyle = {
      height: containerHeight,
      position: "absolute",
      //backgroundColor: 'red',
      overflow: "auto",
    };
  } else {
    containerStyle = {
      height: "100%",
      //      position: 'absolute',
      //backgroundColor: 'red',
      overflow: "auto",
    };
  }

  const groupedMessagesMemo = useMemo(()=> {
    /// console-.log('debug --- groupedMessagesMemo')
    return (
      groupedMessages
      .reverse()
      .map((item, index) => (
          <MessageItem
            item={item}
            user={user}
            showInputComponent={
              index == 0 /*(groupedMessages.length-1)*/ ? true : false
            }
            {...actionProps}
            businessAvatar={businessAvatar}
            containerWidth={containerWidth}
          />
      ))
    )
  }, [groupedMessages])

  /// console-.log('debug --- groupedMessages', groupedMessages)

  return (
    <div className={classes.root} ref={listDiv} style={containerStyle}>
      <div
        className={classes.messageTop}
        style={{ minHeight: containerHeight }}
      />

      <div
        style={{
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {conversation.isFetching && (
          <CircularProgress
            color="lightgray"
            disableShrink={true}
            size="1.5rem"
          />
        )}
      </div>

      {/*
      <div className={classes.messageTop} style={{ minHeight: containerHeight-40 }} />
      <Typography variant='body1' className={classes.chatInfo} gutterBottom>

      { initialMessage }

      </Typography>
      */}

      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          //transform: 'scale(1,-1)'
          //flexWrap: 'wrap-reverse'
        }}
      >
        {typingAt > 0 && ChatMessageMemo}

        {groupedMessages.length > 0 && groupedMessagesMemo}
      </div>
      <div ref={bottomDiv} style={{ height: "80px" }} />
    </div>
  );
};

export default withResizeDetector(
  withStyles(styles, { withTheme: true })(MessageList)
);
