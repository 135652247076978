import React, {Suspense} from "react";

import {
  Page,
  ClusterPage,
  PropertyPage,
  ContentPage,
  FeaturePage,
  ContactDetail,
  SalesDetail,
  VideoPlayerPage,
} from "../views/contents";

// const FeaturePage = React.lazy(() => import('../views/contents/FeaturePage'));

export const contentRoutes = [
  {
    path: "/",
    exact: true,
    main: () => <Page pageKey="home" />,
  },
  {
    path: "/cluster/:clusterKey",
    exact: true,
    main: () => <ClusterPage />,
  },
  {
    path: "/property/:propertyKey",
    exact: true,
    main: () => <PropertyPage />,
  },  
  {
    path: "/feature/:featureKey",
    exact: true,
    main: () => <FeaturePage />,
  },  
  // {
  //   path: "/feature/:featureKey",
  //   exact: true,
  //   main: () => (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <FeaturePage />
  //     </Suspense>
  //   ),
  // },  
  {
    path: "/content/:contentKey",
    exact: true,
    main: () => <ContentPage />,
  },
  {
    path: "/contact/:contactId",
    exact: true,
    main: () => <ContactDetail />,
  },
  {
    path: "/sales/:salesId",
    exact: true,
    main: () => <SalesDetail />,
  },
  {
    path: "/video",
    exact: true,
    main: () => <VideoPlayerPage />,
  },  
];
