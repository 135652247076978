import ls from 'local-storage'
import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

import moment from 'moment'

// Data structure
export const WSSTATE = {
  Idle: "Idle",
  Connecting: "Connecting",
  Connected: "Connected",
  Disconnected: "Disconnected"
}

const conversationInitial = {
  key: null, // Conversation ID
  isFetching: false,
  lastError: null,
  lastFetched: null,
  lastUpdate: null,
  data: { new_count: 0, unread_count: 0, message_count: 0 },
  hasMoreMessages: true,
  messages: [],
  pendingPosts: [],
}

const messageInitial = {
  isSending: false,
  lastError: null,
  lastUpdate: null,
  key: null,
  data: { }
}

const conversationGroupInitial = {
  key: null, // Group code
  name: null, 
  isFetching: false,
  lastError: null,
  lastFetched: 0,
  counts: { count: 0, not_answered_count: 0, new_messages_count: 0 },
  data: { offset: 0, total: 0, records: [] }
}

const conversationCountsInitial = {
  isFetching: false,
  lastFetched: 0,
  lastError: null,
  data: {}
}

const connectionInitial = {
  wsState: WSSTATE.Idle
}

// Action constants
const CONNECTION_ACTION = {
  wsStateChanged: "wsStateChanged"  
}

const COUNT_ACTION = {
  requestConversationCounts: "requestConversationCounts",
  receiveConversationCounts: "receiveConversationCounts",
  errorRequestConversationCounts: "errorRequestConversationCounts",  
}

const GROUP_ACTION = {
  receiveConversationGroupCounts: "receiveConversationGroupCounts",
  requestConversationList: "requestConversationList",
  receiveConversationList: "receiveConversationList",
  errorRequestConversationList: "errorRequestConversationList",
  receiveGroupConversation: "receiveGroupConversation",
}

const ACTION = {
  requestConversation: "requestConversation",
  receiveConversation: "receiveConversation",
  receiveNewMessage: "receiveNewMessage",  
  errorRequestConversation: "errorRequestConversation",
  requestMessages: "requestMessages",
  receiveMessages: "receiveMessages",
  errorRequestMessages: "errorRequestMessages",
  postMessage: "postMessage",
  receivePostMessageResult: "receivePostMessageResult",
  errorPostMessage: "errorPostMessage",
  readAllMessages: "readAllMessages",
  startTyping: "startTyping",
  receiveConversationMessagesUpdate: "receiveConversationMessagesUpdate",
}

// State data retrieval

export const getConversationGroups = (state) => {
  return state.service.conversation.groups
}

export const getConversationGroup = (state, key) => {
  return state.service.conversation.groups[key] || {
    ...conversationGroupInitial,
    key
  }
}

export const getConversation = (state, key) => {
  return state.service.conversation.records[key] || {
    ...conversationInitial,
    key
  }
}

export const getConversationCounts = (state) => {
  return state.service.conversation.counts
}

export const getConnectionState = (state) => {
  return state.service.conversation.connection.wsState
}

// Async action creator

export const fetchConversationCounts = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getState().service.conversation.counts
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/counts/"
    
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch(requestConversationCounts()); 
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveConversationCounts(json)))
      .catch(error => dispatch(errorRequestConversationCounts(error)));
  }
}

export const fetchConversationList = (groupKey, refresh=false) => {
  return (dispatch, getState) => {
    let res = getConversationGroup(getState(), groupKey)
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/"
    url += "?group=" + groupKey

//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch(requestConversationList(groupKey)); 
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveConversationList(groupKey, json)))
      .catch(error => dispatch(errorRequestConversationList(groupKey, error)));
  }
}

export const fetchConversation = (conversationKey, refresh=false) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    if (c && (c.isFetching || (c.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/"
      
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch(requestConversation(conversationKey)); 
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => receiveConversation(conversationKey, json))
      .catch(error => dispatch(errorRequestConversation(conversationKey, error)));
  }
}

export const startNewConversation = (completed) => {
  return (dispatch, getState, args) => {
    
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/start/"
    
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid
    
    return fetch(url, { 
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
        body: JSON.stringify({})
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveConversation(json.uid, json))
        if (completed)
          completed({
            key: json.uid,
            ...json
          }, null)
      })
      .catch(error => {
        if (completed)
          completed(null, error)
      })
  }
}

export const fetchMessages = (conversationKey, refresh=false) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    if (c.isFetching)
      return Promise.resolve()

    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/"
    
    const size = 20
    const message_start_from = 0
    if (!refresh && c.messages.length > 0 && !c.messages[0].data.is_first_message)
      message_start_from = c.messages[0].key
      
    url = url + `?message_size=${size}&message_start_from=${message_start_from}`
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    dispatch(requestMessages(conversationKey)); 
    return fetch(url, { 
              headers: { 
              'X-Service-Key': service_key,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveMessages(conversationKey, json)))
      .catch(error => dispatch(errorRequestMessages(conversationKey, error)))
  }
}

export const sendMessage = (conversationKey, text, files=null) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)

    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/send_message/"
      
    if (!text && !(files && files.length))
      return Promise.resolve()
    
    // c.lastFetched might null if user try to send message
    // while loading message contents for the first time.
    // Keep trying.
//    if (c && c.lastFetched)
    if (c)
      url = url + "?conversation=" + c.data['uid']
    else
      return Promise.resolve()
      

//    alert("sendMessage: " + url)


    
    // Create new Message object
    let message = {
      ...messageInitial,
      key: new Date().getTime(),
      data: { body: text }
    }
    
//    alert("sendMessage: " + JSON.stringify(message, null, 2))
//    alert("conversationKey: " + conversationKey)

    dispatch(postMessage(conversationKey, message)); 
    
    let data = {
      body: text
    }    
    
    let formData = new FormData()
    formData.append('body', text)
    if (files)
      files.map((file, index) => {
  //      formData.append(`files${index}`, file)
        formData.append('attachments', file)
      })
    
    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid
        
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
//          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
//        body: JSON.stringify(data)
        body: formData
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receivePostMessageResult(conversationKey, message, json))
        // Refresh conversation to quickly get operator typing_parties
        // This is a temporary hack until we implement websocket for
        // realtime push
//        dispatch(fetchConversation(conversationKey, true))
      })
      .catch(error => dispatch(errorPostMessage(conversationKey, message, error)));
  }
};

export const markReadAll = (conversationKey) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    // Must not check isFetching as we often do fetching and when it's fetching
    // we don't have any chance to mark read
//    if (c.isFetching || !c.lastFetched)
//      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/read_all/"
      
    dispatch(readAllMessages(conversationKey))
    
//    alert("Fetching: " + url)

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
        body: JSON.stringify({})
      })    
      .then(response => response.json())
      .then(json => {
//        alert(JSON.stringify(json, null, 2))
      })
  }
}


export const startTyping = (conversationKey) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/start_typing/"
      
    let authorization = {}
    let user = getState().service.user

    dispatch({
      type: ACTION.startTyping,
      key: conversationKey,
      agentID: user.userInfo.uid
    })

    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
        body: JSON.stringify({})
      })    
      .then(response => response.json())
      .then(json => {
//        alert(JSON.stringify(json, null, 2))
      })
  }
}

export const closeConversation = (conversationKey) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/close/"

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
        body: JSON.stringify({})
      })    
      .then(response => response.json())
      .then(json => {
        dispatch(receiveConversation(conversationKey, json))
      })
  }
};


export const reopenConversation = (conversationKey) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/service/conversation/" + c.key + "/reopen/"

    let authorization = {}
    let user = getState().service.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    let service_key = user.userInfo.business_uid

    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-Service-Key': service_key,
          ...authorization
        },
        body: JSON.stringify({})
      })    
      .then(response => response.json())
      .then(json => {
        dispatch(receiveConversation(conversationKey, json))
      })
  }
};

// Sync action creators for connection action

export const wsStateChanged = (state) => ({
  type: CONNECTION_ACTION.wsStateChanged,
  state: state
})

// Sync action creators for conversation counts

export const requestConversationCounts = () => ({
  type: COUNT_ACTION.requestConversationCounts,
})

export const receiveConversationCounts = (json) => {

  let result = json
//  alert("receiveConversationCounts: " + JSON.stringify(result, null, 2))

  return {
    type: COUNT_ACTION.receiveConversationCounts,
    result,
    receivedAt: Date.now()
  };
}

export const errorRequestConversationCounts = (error) => ({
  type: COUNT_ACTION.errorRequestConversationCounts,
  error
})

// Sync action creators for conversation list

export const requestConversationList = (key) => ({
  type: GROUP_ACTION.requestConversationList,
  key
})

export const receiveConversationList = (key, json) => {

  let result = json
  // alert("receiveConversationList: " + JSON.stringify(result, null, 2))

  return {
    type: GROUP_ACTION.receiveConversationList,
    key,
    result,
    receivedAt: Date.now()
  };
}

export const errorRequestConversationList = (key, error) => ({
  type: GROUP_ACTION.errorRequestConversationList,
  key,
  error
})

// Sync action creators for conversation

export const requestConversation = (key) => ({
  type: ACTION.requestConversation,
  key
})

export const receiveConversation = (key, json) => {

  let result = json
  
  return {
    type: ACTION.receiveConversation,
    key,
    result: result,
    receivedAt: Date.now(),
  }
}

export const receiveConversationMessagesUpdate = (key, conversation, messages) => {
  return {
    type: ACTION.receiveConversationMessagesUpdate,
    key,
    conversation,
    messages
  }
}

export const errorRequestConversation = (key, error) => ({
  type: ACTION.errorRequestConversation,
  key,
  error
})

export const requestMessages = (key) => ({
  type: ACTION.requestMessages,
  key
})

export const receiveMessages = (key, json) => {

  let result = json
  
  // alert("receiveMessages: " + JSON.stringify(json, null, 2))
  //alert("receiveMessages: " + JSON.stringify(json.messages, null, 2))

  return {
    type: ACTION.receiveMessages,
    key,
//    result: result,
    conversation: { ...result, messages: null },
    result: result['messages'],
    receivedAt: Date.now(),
  }
}

export const errorRequestMessages = (key, error) => ({
  type: ACTION.errorRequestMessages,
  key,
  error
})

export const postMessage = (key, message) => ({
  type: ACTION.postMessage,
  key,
  message
})

export const receivePostMessageResult = (key, message, json) => {
  let result = json

  return {
    type: ACTION.receivePostMessageResult,
    key,
    message,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorPostMessage = (key, message, error) => ({
  type: ACTION.errorPostMessage,
  key,
  message
})

export const receiveNewMessage = (key, message) => {
//  alert(JSON.stringify(message, null, 2))
  
  return {
    type: ACTION.receiveNewMessage,
    key,
    result: { ...message, conversation: null },
    conversation: message.conversation,
    receivedAt: Date.now()
  }
}

export const readAllMessages = (key) => ({
  type: ACTION.readAllMessages,
  key
})

// Conversation reducer

const conversationRecordReducer = createReducer(conversationInitial, {
  [ACTION.requestConversation]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveConversation]: (state, action) => {
      // alert("receiveConversation: " + JSON.stringify(action.result, null, 2))
      return {
        ...state,
        key: action.result.uid,
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestConversation]: (state, action) => {
    return {
        ...state,
        isFetchingMessages: false,
        isError: true
      }
    },
  [ACTION.requestMessages]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveMessages]: (state, action) => {
      const newMessages = action.result.map(data => ({
        ...messageInitial,
        key: data['id'],
        lastUpdate: action.receivedAt,
        createdAt: moment(data.created_at).toDate(),
        sentAt: moment(data.sent_at).toDate(),
        data
      }))
      
      const existingMessages = state.messages.filter(m => !newMessages.find(x => x.key == m.key))
      const messages = [...newMessages, ...existingMessages].sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        
      let hasMoreMessages = false
      if (messages.length > 0 && !messages[0].is_first_message)
          hasMoreMessages = true
          
      return {
        ...state,
        key: action.conversation.uid,        
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.conversation,
        messages,
        hasMoreMessages
      }
    },
  [ACTION.receiveNewMessage]: (state, action) => {

      // This could be message that's we're still sending, but haven't got response
      // yet from server. Let's do a bit hack by comparing the body.
      if (state.pendingPosts.find(p => {
          if (p.data.body == action.result.body)
            return true
          return false
        })) {
        return state
      }

      let newMessage = {
        ...messageInitial,
        key: action.result['id'],
        lastUpdate: action.receivedAt,
        sentAt: moment(action.result.sent_at).toDate(),
        createdAt: moment(action.result.created_at).toDate(),
        data: action.result
      }

      const existingMessages = state.messages.filter(m => m.key != newMessage.key)
      const messages = [newMessage, ...existingMessages].sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      
      return {
        ...state,
        key: action.conversation.uid,        
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.conversation,
        messages        
      }      
    },
  [ACTION.receiveConversationMessagesUpdate]: (state, action) => {
      const newMessages = action.messages.map(data => ({
        ...messageInitial,
        key: data['id'],
        lastUpdate: action.receivedAt,
        sentAt: moment(data.sent_at).toDate(),
        createdAt: moment(data.created_at).toDate(),
        data
      }))
      
      const existingMessages = state.messages.filter(m => !newMessages.find(x => x.key == m.key))
      const messages = [...newMessages, ...existingMessages].sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        
      return {
        ...state,
        data: action.conversation,
        messages,
      }    
    },
  [ACTION.readAllMessages]: (state, action) => {
      // Now we are relying on messages update push from server
      return state
      
      return {
        ...state,
        data: {
          ...state.data,
          new_count: 0
        }
      }
    },           
  [ACTION.startTyping]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          parties: state.data.parties.map(p => {
            if (p.agent && p.agent.uid == action.agentID)
              return {
                ...p,
                typing_at: new Date()
              }
            else
              return p
          })
        }
      }
    },           
  [ACTION.errorRequestMessages]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        lastError: action.error
      }
    },
  [ACTION.postMessage]: (state, action) => {
      const post = {
        ...action.message,
        isSending: true
      }

      return {
        ...state,
        pendingPosts: [
          ...state.pendingPosts,
          post
          ]
      }
    },
  [ACTION.receivePostMessageResult]: (state, action) => {
      
//      alert("receivePostMessageResult: " + JSON.stringify(action.result, null, 2))

      // Remove from pending posts
      const pendingPosts = state.pendingPosts.filter(
        p => p.key != action.message.key)

      // Add new message
      let newMessage = {
        ...messageInitial,
        key: action.result['id'],
        lastUpdate: action.receivedAt,
        sentAt: moment(action.result.sent_at).toDate(),
        createdAt: moment(action.result.created_at).toDate(),
        data: action.result
      }        
      
      const existingMessages = state.messages.filter(m => m.key != newMessage.key)
      const messages = [newMessage, ...existingMessages].sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        
      let unread_count = 1
      if (state.data && state.data.unread_count !== undefined)
        unread_count = state.data.unread_count + 1
        
      
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        messages,
        pendingPosts,
        data: {
          ...state.data,
          unread_count: unread_count
        },        
      }            
    },
  [ACTION.errorPostMessage]: (state, action) => {
      alert("Error Post Message: " + action.error)
      
      return {
        ...state,
        pendingPosts: state.pendingPosts.map(p => {
          if (p.key == action.message.key)
            return {
              ...action.message,
              isSending: false,
              lastError: action.error
            }
          else
            return p
        })
      }      
    },
})

// Conversation Group reducer
    
const conversationGroupReducer = createReducer(conversationGroupInitial, {
  [GROUP_ACTION.receiveConversationGroupCounts]: (state, action) => {
      return {
        ...state,
        key: action.result.code,
        name: action.result.name,
        counts: action.result        
      }    
    },
  [GROUP_ACTION.requestConversationList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [GROUP_ACTION.receiveConversationList]: (state, action) => {  

      //alert("receiveConversationList:" + JSON.stringify(action, null, 2))
    
      let data
      if (action.result.offset == 0)
        // Replace with new data
        data = {
          ...action.result,
          pending: action.result.total - (action.result.offset + action.result.records.length)
        }
      else
        // Add to bottom of the list
        data = {
          records: [
            ...state.data.records,
            ...action.result.records
          ],
          offset: action.result.offset,
          total: action.result.total,
          pending: action.result.total - (action.result.offset + action.result.records.length)
        }
        
      return {
        ...state,
        key: action.key,
        lastFetched: action.receivedAt,
        lastError: null,
        isFetching: false,
        data
      }
    },
  [GROUP_ACTION.errorRequestConversationList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        lastError: action.error
      }
    },
  [GROUP_ACTION.receiveGroupConversation]: (state, action) => {
      if (action.result.groups.includes(state.key)) {
        // Add if not exist
        let found = false
        let count = state.counts.count
        let records = state.data.records.map(c => {
          if (c.uid == action.key) {
            found = true
            return action.result
          }
          else
            return c
        })
        
        if (!found) {
          records.unshift(action.result)
          count++
        }
      
        return {
          ...state,
          // counts: { ...state.counts, count },          
          data: {
            ...state.data,
            records
          }
        }
      }
      else {
        // Remove
        let count = state.counts.count
        let records = state.data.records.filter(c => {
          if (c.uid == action.key) {
            count--
            return false
          }
          else
            return true
        })
        
        return {
          ...state,
          // counts: { ...state.counts, count },          
          data: {
            ...state.data,
            records
          }
        }
      }
    }
})

// Conversation Counts reducer
  
const conversationCountsReducer = createReducer(conversationCountsInitial, {
  [COUNT_ACTION.requestConversationCounts]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [COUNT_ACTION.receiveConversationCounts]: (state, action) => {  
      // alert("receiveConversationCounts" + JSON.stringify(action, null, 2))
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [COUNT_ACTION.errorRequestConversationCounts]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        lastError: action.error
      }
    },
})

// Connection reducer

const connectionReducer = createReducer(connectionInitial, {
  [CONNECTION_ACTION.wsStateChanged]: (state, action) => {
    return {  
        ...state,
        wsState: action.state
      }
    },
})

// Groups and records by key reducers
const conversationGroupsByKeyReducer = (state={}, action) => {
  if (action.type in GROUP_ACTION) {
    const key = action.key
    return {
      ...state,
      [key]: conversationGroupReducer(state[key], action)
    }
  }
  else if (action.type == ACTION.receiveConversation ||
    action.type == ACTION.receiveConversationMessagesUpdate) {
    // Update conversation in conversation list
    let newState = {}
    
    //alert(action.key)
    //alert(JSON.stringify(action.result, null, 2))    
    
    Object.values(state).forEach(group => {
      const key = group.key
      newState[key] = conversationGroupReducer(group, {
        type: GROUP_ACTION.receiveGroupConversation,
        key: action.key,
        result: action.result || action.conversation
      })
    })
    
    return newState
  }
  else if (action.type == COUNT_ACTION.receiveConversationCounts) {
    // Update our count stats
    let newState = {}
    
    action.result.groups.forEach(group => {
      const key = group.code
      newState[key] = conversationGroupReducer(state[key], {
          type: GROUP_ACTION.receiveConversationGroupCounts,
          result: group,
        })
    })
    
    // alert("groups: " + JSON.stringify(newState, null, 2))
    
    return {
      ...state,
      ...newState
    }
  }

  return state
}

const conversationRecordsByKeyReducer = (state={}, action) => {
  if (action.type in ACTION) {
    const key = action.key
    return {
      ...state,
      [key]: conversationRecordReducer(state[key], action)
    }
  }
  
  return state
}

// Combine counts, groups and records reducer

const conversationReducer = combineReducers({
  connection: connectionReducer,  
  counts: conversationCountsReducer,
  groups: conversationGroupsByKeyReducer,
  records: conversationRecordsByKeyReducer,
});

export default conversationReducer