import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { fetchActivityHistory } from "../../store/service/networkManager/activityHistoryManager";
import Header from "../../components/Header";

import useStyles from "./styles";

const ContactList = ({ activeView }) => {
  const [selected, setSelected] = useState("");
  const [scroll, setOnScroll] = useState(false);
  const [queryString, setQueryString] = useState({
    q: null,
    size: 25,
    offset: 0,
  });
  const wrapperRef = useRef(null);
  const activityHistory = useSelector((state) => state.service.activityHistory);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const dateNow = moment();

  const { isFetching, data, isFetchingLazyLoad } = activityHistory;

  useEffect(() => {
    dispatch(fetchActivityHistory(false, null, true, false));
    return () => {
      dispatch(fetchActivityHistory(false, null, true, false));
    };
  }, [activeView]);

  // useEffect(() => {
  //   // console.log(activityHistory);
  //   if (wrapperRef) {
  //     wrapperRef.current.scrollTo(0, 0);
  //   }
  // }, [queryString]);

  if (data && data.records && !data.records.length > 0) {
    return <Typography>No data</Typography>;
  }

  const handleSelected = (uid, activityUID) => {
    setSelected((prevState) => (prevState === activityUID ? "" : activityUID));
    history.push(`/contact/${uid}?activity=${activityUID}`);
  };

  const onSearch = (e) => {
    setQueryString({ ...queryString, q: e.target.value, offset: 0 });
    const q = e.target.value;
    if (q.length >= 3) {
      setTimeout(() => {
        dispatch(fetchActivityHistory(false, { q }, true, true));
      }, 200);
      return;
    }
    if (!q || q.length < 1) {
      const newQueryString = queryString;
      delete newQueryString.q;
      dispatch(fetchActivityHistory(false, null, true, true));
      return;
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0) {
      if (scroll === false) {
        setOnScroll(true);
      }
    } else {
      setOnScroll(false);
    }
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (data && data.records.length > 0) {
        const newQueryString = queryString;
        !newQueryString.q && delete newQueryString.q;
        if (!isFetchingLazyLoad && data.records.length < data.total) {
          dispatch(fetchActivityHistory(true, null, false, false));
          return;
        }
      }
    }
  };

  return (
    <>
      <Header
        title="Activity History"
        searchInput={true}
        onChangeSearchInput={onSearch}
        contentScroll={scroll}
      />
      <Box className={classes.container} onScroll={handleScroll}>
        {!data || isFetching ? (
          <CircularProgress className={classes.loadingIndicator} />
        ) : (
          <List className={classes.contactList}>
            {data.records.map((item, index) => (
              <>
                <ListItem
                  key={index}
                  onClick={() => handleSelected(item.contact.uid, item.uid)}
                  className={`${classes.listItem} ${
                    selected === item.uid ? classes.listItemSelected : ""
                  }`}
                >
                  <ListItemText
                    className={`${
                      selected === item.uid ? classes.selectedListItem : ""
                    }`}
                    primary={
                      <>
                        <Box display="flex" alignItems="baseline">
                          <Box flexGrow={1}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 700 }}
                              noWrap={true}
                              color="textPrimary"
                            >
                              {item.contact.first_name}
                              {"\n"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              noWrap={true}
                            >
                            {moment(item.activity_time).fromNow(true)}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    }
                    secondary={
                      <Box className={classes.wrapperSecondaryListItem}>
                        <Box className={classes.secondaryListItem}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            component="span"
                          >
                            {item.activity_type.name}
                          </Typography>
                          {item.custom_data && item.custom_data.activity_result &&
                            <>
                              &nbsp;/&nbsp;
                              <Typography
                                variant="subtitle1"
                                color="textPrimary"
                                component="span"
                              >
                                {item.custom_data.activity_result}
                              </Typography>
                            </>
                          }
                        </Box>
                        <Box className={classes.secondaryListItem}>
                          { item.custom_data && item.custom_data.cancel_reason &&
                            <Typography variant="body2" noWrap={false}>
                              Reason: {item.custom_data.cancel_reason}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.hold_reason &&
                            <Typography variant="body2" noWrap={false}>
                              Reason: {item.custom_data.hold_reason}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.next_action &&
                            <Typography variant="body2" noWrap={false}>
                              Next Action: {item.custom_data.next_action}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.visit_date &&
                            <Typography variant="body2" noWrap={false}>
                              Visit Date: {item.custom_data.visit_date}
                            </Typography>
                          }
                          
                        </Box>
                      </Box>                      
                    }
                  />
                </ListItem>
                <Divider light />
              </>
            ))}
          </List>
        )}
        {isFetchingLazyLoad && (
          <CircularProgress
            className={`${classes.loadingIndicator}  ${classes.loadingIndicatorBottom}`}
          />
        )}
      </Box>
    </>
  );
};

export default ContactList;
