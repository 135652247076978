import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import {
  getConversationGroup,
  fetchConversationList,
  getConnectionState,
  startNewConversation,
  WSSTATE,
} from "../../store/service";

import ConversationView from "./ConversationView";
import Header from "./Header";

const ConversationController = (props) => {
  const { conversationKey, setConversationKey, setActiveView } = props;

  const [isStarting, setIsStarting] = useState(false);

  const theme = useTheme();

  const dispatch = useDispatch();

  const connectionState = useSelector((state) => getConnectionState(state));
  const conversationList = useSelector((state) =>
    getConversationGroup(state, "me")
  );

  useEffect(() => {
    if (!conversationList.lastFetched && !conversationList.isFetching) {
      dispatch(fetchConversationList("me"));
    }
  }, [connectionState]);

  useEffect(() => {
    if (conversationKey <= 0 && conversationList.lastFetched && !isStarting) {
      let lastConversationKey = null;
      if (
        conversationKey == -1 &&
        conversationList.lastFetched &&
        conversationList.data.records.length > 0
      ) {
        const c = conversationList.data.records.find((c) => {
          if (c.initiated_by.agent) {
            return true;
          }
          return false;
        });

        if (c) {
          lastConversationKey = c.uid;
        }
      }

      if (lastConversationKey) {
        setConversationKey(lastConversationKey);
      } else if (connectionState == WSSTATE.Connected) {
        // Start new conversation
        //        alert("Start conversation...")
        dispatch(
          startNewConversation((conversation, error) => {
            if (error) {
              alert("Can not start conversation");
            } else {
              //            alert(JSON.stringify(conversation, null, 2))
              //            alert("Start conversation returns: " + conversation.key)
              setTimeout(() => {
                setConversationKey(conversation.key);
                setIsStarting(false);
              }, 100);
            }
          })
        );

        setIsStarting(true);
      }
    }
  }, [conversationList.lastFetched, connectionState, conversationKey]);

  const conversationViewMemo = useMemo(()=> {
    /// console-.log('debug --- conversationViewMemo')
    return (
      <Box height="100%" style={{ position: "relative", overflow: "auto" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: 100,
            display: "none",
          }}
        >
          <Header />
        </div>
        <ConversationView
          setActiveView={setActiveView}
          conversationKey={conversationKey}
        />
      </Box>
    )
  }, [setActiveView, conversationKey])

  if (conversationKey > 0) {
    /// console-.log('debug --- conversationKey', conversationKey)
    return conversationViewMemo;
  } else {
    return (
      <div>
        {connectionState}
        <br />
        {isStarting && "Starting..."}
        {!conversationList.lastFetched &&
          conversationList.isFetching &&
          "Loading..."}
      </div>
    );
  }
};

export default ConversationController;
