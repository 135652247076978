import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'

import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import Button from '@material-ui/core/Button'


const styles = theme => ({
  root: {
//    marginTop: theme.spacing.unit * 2,
//    marginBottom: theme.spacing.unit * 2,
    display: 'block',
//    position: 'fixed',
//    bottom: 0
    
  },
  composeBarPaper: {
    display: 'flex',
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: 'auto',
//    maxWidth: 500,
    alignItems: 'center',
  },
  filesPreviewPaper: {
    display: 'flex',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  composeInput: {
//    padding: `${theme.spacing.unit * 2}`,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: 0, //theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit * 0,
    paddingBottom: theme.spacing.unit * 0,
    width: '100%'
  },
  composeIcon: {
    padding: theme.spacing.unit
  },
  composeSendIcon: {
    padding: theme.spacing.unit,
  },
  displayNone: {
    display: 'none'
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  previewImageDiv: {
    width: '100%',
    borderColor: '#eeeeee',
    borderWidth: 1
  },
  muted: {
    color: '#999'
  },
})

const FilePreview = ({ classes, file }) => {
  const [ image, setImage ] = useState(null)
  
  useEffect(() => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result)
    };
    reader.readAsDataURL(file);
  }, file)
  
  return (
    <div className={classes.previewImageDiv}>
      <img src={image} className={classes.previewImage}/>
    </div>
  )
}

const ComposeBar = ({ theme, classes, sendMessage, defaultValue, disabled, onChange }) => {

  const [message, setMessage] = useState({body: ""})
  
  const [files, setFiles] = useState([])
  
  const handleChange = name => event => {
    setMessage({ ...message, [name]: event.target.value });
    if (onChange)
      onChange(message)
  } 
  
  const handleKeyDown = event => {
    if (event.keyCode == 13)
      send()
  }
  
  const send = () => {
    sendMessage(message.body, files)
    clear()
  }
  
  const clear = () => {
    setMessage({ ...message, body: "" })
    setFiles([])
  }
  
  useEffect(() => {
    if (defaultValue)
      setMessage({
        ...message,
        body: defaultValue
      })
  }, [defaultValue])
  
  const addFiles = ({ target }) => {
    let newFiles = []
    for (let i = 0; i < target.files.length; i++) {
      let file = target.files[i]
      newFiles.push(file)
    }
    
    setFiles([...files, ...newFiles])
  }
  
  return (
    <Box className={classes.composeBar}>
      { files.length ?
        <Paper className={classes.filesPreviewPaper} elevation={0}>
          <Grid container spacing={1}>
            { files.map(file => (
              <Grid item xs={3}>              
                <FilePreview classes={classes} file={file} />
              </Grid>
              ))
            }
          </Grid>
        </Paper>
        :
        <></>
      }
      <Paper className={classes.composeBarPaper} elevation={0}>      
        <Input placeholder="Tulis pesan anda" className={classes.composeInput} margin="normal" variant="filled" disableUnderline={true} value={message.body} onChange={handleChange("body")} onKeyDown={handleKeyDown}
          disabled={disabled} />
        { !disabled &&
        <>
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            onChange={addFiles}
            type="file"
            disable={disabled}
          />
          <label htmlFor="raised-button-file">
            <IconButton size="medium" component="span"
              className={ classes.composeSendIcon }
              >
              <AddAPhotoIcon />
            </IconButton>        
          </label>         
          <IconButton size="medium" 
            className={ `${classes.composeSendIcon}, ${!message.body && !(files && files.length) && classes.displayNone}` } 
              onClick={() => send() }
            >
            <SendIcon />
          </IconButton>
        </>
        }
      </Paper>
    </Box>  
  )
}

export default withStyles(styles, { withTheme: true })(
  ComposeBar
  )