import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    width: "27%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: COLORS.greyBackground,
    backgroundColor: "#efefef",
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  onlyIcon: {
    paddingBottom: 0,
    backgroundColor: "transparent",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2) - 2,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    // background:
    //   "linear-gradient(0deg, rgba(66,66,66,0.13068977591036413) 28%, rgba(42,42,42,0.5536589635854341) 47%, rgba(48,48,48,1) 100%)",
  },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainFlexColumn: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
  btnRight: {
    alignSelf: "flex-end",
  },
  mainButton: {},
  wrapperSearchInput: {
    display: "flex",
    justifyContent: "center",
    // marginLeft: theme.spacing(-1),
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  searchInput: {
    alignSelf: "center",
    flexGrow: 1,
    fontSize: "18px",
    color: "black",
    backgroundColor: "#ddd",
    paddingLeft: "10px",
    borderRadius: "5px",
    border: 0,
    height: "36px",
  },
  gradient: {
    height: "50px",
    background:
      "linear-gradient(0deg, rgba(48,48,48,0.11388305322128855) 20%, rgba(48,48,48,0.4556197478991597) 38%, rgba(48,48,48,1) 80%)",
    width: "100%",
    marginBottom: "-50px",
  },
}));

export default useStyles;
