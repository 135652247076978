import React, { useState, useEffect, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Route,
  Link as RouterLink,
  withRouter,
  useLocation,
} from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import { 
  getContentRecord, 
  fetchContentRecord,
  logSessionEvent,  
} from "../../store/service";

import Page from "./Page";


function humanize(str) {
  if (str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  return str
}

function isNumber(str) {
  // if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const styles = (theme) => ({
  root: {
    //    maxWidth: 480,
    //    display: 'flex',
    width: "100%",
    minHeight: "100vh",
  },
  content: {},
  divider: {
    width: "100%",
    height: theme.spacing.unit,
    //    backgroundColor: theme.palette.common.white,
    backgroundColor: "#eee",
  },
});

const PropertyPage = ({
  classes,
  history,
  openLink,
  isFetching,
  lastFetched,
  data,
  logEvent,
  fetchPropertyDetail,
  propertyKey,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    fetchPropertyDetail()
    if (lastFetched)
      logEvent('view', { 
          type: 'content',
          model: 'property',
          uid: data.uid, 
          url: window.location.href,
          title: `Property ${data.name}`,
      })          
  }, [propertyKey, lastFetched]);

  useEffect(() => {
    fetchPropertyDetail(true);
  }, [pathname]);

  const renderText = (text, context) => {
    if (!text || !context) {
      return "";
    }

    return text.replace(/\{\{(.*?)\}\}/gi, (m, p1) => {
      const name = p1.trim();
      if (name in context) {
        const value = context[name];
        if (isNumber(value))
          return numberWithCommas(parseFloat(value))
        else
          return value              
      } else {
        return "";
      }
    });
  };

  const renderSections = (props) => {
    const { createSection, createContentBlock, divider, pageData } = props;

    const sectionsData = pageData.sections.map((s) => {
      let { items } = s;
      if (s.key == "hero") {
        if (lastFetched) {
          //s.background_images = data.images
          s.images = data.images
        }
      } else if (s.key == "gallery") {
        if (lastFetched && data.images) {
          items = data.images.map((i) => ({
            title: i.title, // || i.file_name,
            images: [i],
          }))
        }
        else if (lastFetched && data.gallery) {
          items = data.gallery.map((i) => ({
            title: i.title,
            images: i.images,
          }))
        }
        else
          items = []
      } else if (s.key == "site-plan") {
        if (lastFetched && data.site_plan_doc) {
          items = [{
            title: data.site_plan_doc.title, // || i.file_name,
            images: [data.site_plan_doc],            
          }]
        }        
        else if (lastFetched && data.site_plan) {
          items = data.site_plan.map((i) => ({
            title: i.title,
            images: i.images,
          }))
        }
        else
          items = []
      } else if (s.key == "property-details") {
        if (lastFetched) {
          const excludedKeys = ["uid", "id", "key", "model", "created_at", "images", "posts", "gallery",]
          const itemTemplate = s.items[0]
          
          items = Object.keys(data).reduce((p, key) => {
            if (excludedKeys.includes(key))
              return p
            let value = data[key]
            if (!value)
              return p
              
            if (value.constructor == Object) {
              value = value.name
              if (!value)
                return p
            }
            
            const itemData = {
              name: humanize(key),
              value,
            }
            const item = {
              ...itemTemplate,
              title: renderText(itemTemplate.title, itemData),
              subtitle: renderText(itemTemplate.subtitle, itemData),
              body: renderText(itemTemplate.body, itemData),
            }              
              
            return [
              ...p,
              item
            ]
          }, [])
        }
        else
          items = []
      }      
      
      
      return {
        ...s,
        title: renderText(s.title, data),
        subtitle: renderText(s.subtitle, data),
        body: renderText(s.body, data),
        items,
      };
    });

    const sections = sectionsData
      .map((sectionData, index) => createSection(sectionData, index))
      // Divider
      .reduce((prev, next) => [prev, divider, next]);

    return sections;
  };

  const renderHelmet = ({ pageData }) => {
    let title = "Property Detail";
    if (lastFetched) {
      title = data.name;
    }

    return (
      <title>
        {title} - {process.env.REACT_APP_TITLE_SUFFIX}
      </title>
    );
  };

  if (isFetching) {
    return (
      <Box style={{ width: "100%", height: "100vh", backgroundColor: "white" }}>
        <Typography>Loading</Typography>
      </Box>
    );
  } else {
    return (
      <Page
        pageKey="property-detail"
        renderSections={renderSections}
        renderHelmet={renderHelmet}
        propertyKey={propertyKey}
        doNotLogEvent={true}
      />
    );
  }
};

function propertyKeyFromProps(props) {
  let key = null;
  if (props.propertyKey) {
    key = props.propertyKey;
  } else if (props.match) {
    key = props.match.params.propertyKey;
  }
  return key;
}

const mapStateToProps = (state, ownProps) => {
  const key = propertyKeyFromProps(ownProps);
  return {
    ...getContentRecord(state, "property", key),
    propertyKey: key,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const key = propertyKeyFromProps(ownProps)
  // Fix search is null if there's hash  
  let urlParams = new URLSearchParams(ownProps.location.search)
  if (ownProps.location.hash && ownProps.location.hash.indexOf('?') != -1)  
    urlParams = new URLSearchParams(ownProps.location.hash.substring(
      ownProps.location.hash.indexOf('?')))
  
  return {
    fetchPropertyDetail: (refresh) => {
      dispatch(fetchContentRecord("property", key, refresh))
    },
    logEvent: (eventName, eventParam) => {
      // Get session id param if any
      const sessionUid = urlParams.get("session")
      if (sessionUid)
        dispatch(logSessionEvent(sessionUid, eventName, eventParam))
    },   
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(PropertyPage)
  )
);
