import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    maxHeight: "82%",
    marginTop: "105px",
    //overflowY: "scroll",
    //overflowX: "hidden",
    //paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxHeight: window.innerHeight - 210,
      marginTop: "115px",
      paddingBottom: theme.spacing(10),
    },
  },
  loadingIndicator: {
    alignSelf: "center",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxHeight: "70%",
      marginTop: 0,
    },
  },
  loadingIndicatorBottom: {
    marginTop: theme.spacing(-5),
  },
  // contactHeader: {
  //   position: "fixed",
  //   flexGrow: 1,
  //   display: "flex",
  //   flexDirection: "column",
  //   backgroundColor: COLORS.greyBackground,
  //   zIndex: 99,
  //   top: theme.spacing(4),
  // },
  sectionTitle: {
    fontWeight: 700,
  },
  wrapperSearchInput: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  searchInput: {
    alignSelf: "center",
    flexGrow: 1,
    fontSize: "18px",
    backgroundColor: COLORS.secondary,
    paddingLeft: "10px",
    borderRadius: "5px",
    border: 0,
    height: "40px",
  },
  contactList: {
    // marginTop: theme.spacing(10),
  },
  listItem: {
    cursor: "pointer",
  },
  listItemSelected: {
    borderLeft: "2px solid white",
  },
  selectedListItem: {
    marginLeft: "-2px",
  },
}));

export default useStyles;
