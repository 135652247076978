import React, { useState, useRef, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from '@material-ui/core/Slider';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
  textField: {},
  formControl: {
    // Text field has top spacing. Let's add it
    // to other control
    marginTop: theme.spacing(2),
  },
});

function getFormValues(formFields) {
  let values = {}
  formFields.forEach(ff => {
    if ("value" in ff)      
      values[ff.field_name] = ff.value
  })
  return values
}

const FormInput = ({
  classes,
  theme,
  content,
  sendMessage,
  openLink,
  animated,
}) => {
  const formFields = content.form_fields;

  const [formValues, setFormValues] = useState(getFormValues(formFields))
  const [errorTexts, setErrorTexts] = useState({})

  const validateEmail = (email) => {};

  const isValid = (val, required = true, type = null) => {
    if (!val || val == undefined) {
      val = "";
    } 
    else if (Array.isArray(val)) {
      if (required && val.length == 0)
        return false
      return true
    }
    else {
      val = val.trim();
    }
    if (required && val.length == 0) {
      return false;
    }
    if (val.length > 0) {
      if (type == "email") {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(val).toLowerCase())) {
          return false;
        }
      }
    }

    return true;
  };

  const validateAndSave = (e = null) => {
    //alert(JSON.stringify(formValues, null, 2))
    
    const errors = {};

    if (e && e === "cancel") {
      sendMessage(JSON.stringify({ value: "cancel", text: "Cancel" }));
      return;
    }

    formFields.forEach((ff) => {
      if (!isValid(formValues[ff.field_name], ff.required === "1", ff.validation)) {
        errors[ff.field_name] = "Invalid";
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrorTexts(errors);
      // alert("Please check some invalid input.");
      return;
    }

    // Clean up formValues
    const cleanedValues = {}
    Object.keys(formValues).forEach(key => {
      let val = formValues[key]
      if (typeof val === "string")
        val = val.trim()

      cleanedValues[key] = val
    })

    const text = formFields
      .filter((ff) => {
        if (ff.field_name in cleanedValues) {
          const val = cleanedValues[ff.field_name]
          if (val === "" || val === null)
            return false
          if (Array.isArray(val) && val.length <= 0)
            return false
          return true
        }
      })
      .map((ff) => {
        let val = cleanedValues[ff.field_name];        
        if (!val) {
          val = "";
        } 
        else if (ff.input_type == "option" || ff.input_type == "radio") {
          const opt = ff.options.find((o) => o.value == val)
          if (opt)
            val = opt.text;
        }
        else if (ff.input_type == "checkbox") {
          // Loop all values
          val = val.map(v => {
            const opt = ff.options.find((o) => o.value == v)
            if (opt)
              return opt.text
             return v
            })
            .reduce((p, n) => p ? p + ", " + n : n, null)
        }
        else if (ff.input_type == "range_slider") {
          // Loop all values
          val = val.map(v => {
            const opt = ff.marks[v]
            if (opt)
              return opt.text
            return v
            })
            .reduce((p, n) => p ? p + "-" + n : n, null)
          }        
        return `${ff.label}: ${val}`;
      })
      .reduce((p, n) => p ? p + ", " + n : n, null)
      
    //alert(text)

    sendMessage(
      JSON.stringify({
        form_values: cleanedValues,
        text: text,
      })
    );
  };
  
  const onFormValueChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const onValueChanged = (name) => (event) => onFormValueChange(name, event.target.value)

  
  const checkBoxChecked = (name, option) => {
    if (Array.isArray(formValues[name]))
      return formValues[name].includes(option.value === undefined ? option.text : option.value)
    return false
  }
  const onCheckBoxChange = (name, option) => (event) => {
    const values = Array.isArray(formValues[name]) ? formValues[name] : []
    const value = option.value === undefined ? option.text : option.value
    if (event.target.checked) {
      if (!values.includes(value))
        onFormValueChange(name, [...values, value])
    }
    else {
      if (values.includes(value))
        onFormValueChange(name, values.filter(v => v != value))
    }
  }

  const controls = formFields.map((ff) => {
    if (ff.input_type == "text") {
      return (
        <TextField
          label={ff.label}
          value={formValues[ff.field_name]}
          error={ff.field_name in errorTexts ? true : false}
          onChange={onValueChanged(ff.field_name)}
          className={classes.textField}
          margin="normal"
          fullWidth
        />
      );
    } else if (ff.input_type == "date") {
      return (
        <TextField
          label={ff.label}
          InputLabelProps={{
            // Without this, it will cause overlapped label and date format on Chrome
            shrink: true,
          }}
          value={formValues[ff.field_name]}
          error={ff.field_name in errorTexts ? true : false}
          onChange={onValueChanged(ff.field_name)}
          className={classes.textField}
          type="date"
          margin="normal"
          fullWidth
        />
      );
    } else if (ff.input_type == "option") {
      return (
        <FormControl
          className={classes.formControl}
          error={ff.field_name in errorTexts ? true : false}
          >
          <InputLabel id="demo-simple-select-label">{ff.label}</InputLabel>
          <Select
            value={formValues[ff.field_name] || ""}
            onChange={onValueChanged(ff.field_name)}
          >
            {ff.options.map((o) => (
              <MenuItem value={o.value || o.text}>{o.text}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (ff.input_type == "radio") {
      return (
        <FormControl component="fieldset" 
          className={classes.formControl}
          error={ff.field_name in errorTexts ? true : false}
          >
          <FormLabel component="legend">{ff.label}</FormLabel>
          <RadioGroup
            name={ff.field_name}
            value={formValues[ff.field_name] || ""}
            onChange={onValueChanged(ff.field_name)}
            row
          >
            {ff.options.map((o) => (
              <FormControlLabel
                control={<Radio color="primary" />}
                value={o.value === undefined ? o.text : o.value}
                label={o.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    } else if (ff.input_type == "checkbox") {
      return (
        <FormControl component="fieldset" 
          className={classes.formControl}
          error={ff.field_name in errorTexts ? true : false}
          >
          <FormLabel component="legend">{ff.label}</FormLabel>
          <FormGroup row>
            {ff.options.map((o) => (
              <FormControlLabel
                control={<Checkbox 
                    name={o.value}
                    color="primary"
                    checked={checkBoxChecked(ff.field_name, o)} 
                    onChange={onCheckBoxChange(ff.field_name, o)}
                  />
                }
                label={o.text}
              />
            ))}
          </FormGroup>
        </FormControl>
      );
    } else if (ff.input_type == "range_slider") {
      return (
        <FormControl component="fieldset" 
          className={classes.formControl}
          error={ff.field_name in errorTexts ? true : false}
          fullWidth
          >
          <FormLabel component="legend">{ff.label}</FormLabel>
          <Box ml={2} mr={2}>
            <Slider
              defaultValue={formValues[ff.field_name] || [0, ff.marks.length-1]}
              aria-labelledby="discrete-slider-custom"
              min={0}
              step={1}
              max={ff.marks.length-1}
              onChange={(event, newValue) => onFormValueChange(ff.field_name, newValue)}
              valueLabelDisplay="off"
              marks={ff.marks.map((o, index) => ({
                value: index,
                label: o.text
              }))}
            />          
          </Box>
        </FormControl>
      );
    }        
    
      
  });

  return (
    <Box mt={1} ml={3}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width="100%"
        flexWrap="wrap"
        ml={1}
        mr={1}
      >
        <Paper className={classes.root}>
          {Object.keys(errorTexts).length > 0 &&
            <Box mb={1}>
              <Typography variant="body2" style={{ color: "red" }}>
              Please fix input with red mark
              </Typography>
            </Box>
          }
          {controls && controls.map(c =>
            <Box width="100%">
              {c}
            </Box>
          )}

          <Box mt={2} />

          <Button
            variant="contained"
            color="primary"
            onClick={() => validateAndSave()}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="default"
            name="cancel"
            onClick={() => validateAndSave("cancel")}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(FormInput);
