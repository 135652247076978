import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ChatIcon from "@material-ui/icons/Home";
import HistoryIcon from "@material-ui/icons/History";
import GroupIcon from "@material-ui/icons/Group";
import AddIcon from "@material-ui/icons/Add";
import ContactsIcon from "@material-ui/icons/People";
import AccountIcon from "@material-ui/icons/AccountCircle";
import SalesIcon from '@material-ui/icons/AttachMoney';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useMediaQuery, useTheme } from "@material-ui/core";

import ConversationController from "../views/conversation";
import History from "../views/history";
import Contact from "../views/Contact";
import Sales from "../views/sales";
import ActivityHistory from "../views/ActivityHistory";
import {
  getBusinessInfo,
  getUser,
  getConnectionState,
  manageAccount,
  logout,
  startNewConversation,
} from "../store/service";
import { COLORS } from "../config";

import { contentRoutes } from "./routes";
import NavigationView from "./NavigationView";

const NavigationController = (props) => {
  const business = useSelector((state) => getBusinessInfo(state));
  const user = useSelector((state) => getUser(state));
  const dispatch = useDispatch();

  const [activeView, setActiveView] = useState("conversation");
  const theme = useTheme();

  const [conversationKey, setConversationKey] = useState(-1); // Get last conversation

  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    console.log(" ini props dari navigation view ", props);
  }, []);

  const showConversation = (key) => {
    setConversationKey(key);
    setActiveView("conversation");
  };

  let main;
  if (activeView === "conversation") {
    main = (
      <ConversationController
        conversationKey={conversationKey}
        setConversationKey={setConversationKey}
        setActiveView={setActiveView}
      />
    );
  } else if (activeView === "history") {
    main = <History onShowConversation={showConversation} />;
  } else if (activeView === "contacts") {
    main = <Contact setActiveView={setActiveView} activeView={activeView} />;
  } else if (activeView === "sales") {
    main = <Sales setActiveView={setActiveView} activeView={activeView} />;
  } else {
    main = <ActivityHistory />;
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const hideMenu = () => {
    setMenuAnchorEl(null);
  };

  const menuButtons = [];
  menuButtons.push(
    <IconButton onClick={() => setActiveView("conversation")}>
      {/*
      <ChatIcon fontSize='large' style={{ color: activeView === "conversation" ? theme.palette.primary.main : '#aaa' }} />
      */}
      <Avatar
        style={{ width: 50, height: 50, backgroundColor: "#ddd" }}
        src={business.data.avatar && business.data.avatar.image_url}
      />
    </IconButton>
  );

  // menuButtons.push(<Box height="8px" />);

  /*
  menuButtons.push(
    <IconButton onClick={() => showConversation(0)}>
      <AddIcon fontSize='large' style={{ color: '#aaa' }} />
    </IconButton>
  )
  */


  /*  
  menuButtons.push(
    <IconButton onClick={() => setActiveView("activityHistory")}>
      <HistoryIcon fontSize="large" style={{ color: "#aaa" }} />
    </IconButton>
  );
  */
  
  menuButtons.push(
    <Box width={64} />
  );
  

  menuButtons.push(
    <IconButton onClick={() => setActiveView("contacts")}>
      <GroupIcon fontSize="large" style={{ color: activeView === "contacts" ? theme.palette.primary.main : "#aaa" }} />
    </IconButton>
  );
  
  menuButtons.push(
    <IconButton onClick={() => setActiveView("sales")}>
      <SalesIcon fontSize="large" style={{ color: activeView === "sales" ? theme.palette.primary.main : "#aaa" }} />
    </IconButton>
  );
  
  /*
  menuButtons.push(
    <IconButton
      onClick={() => setActiveView("activityHistory")}
      disabled={true}
    >
      <GroupIcon
        fontSize="large"
        style={{ color: matchesSmDown ? "#424242" : "#303030" }}
      />
    </IconButton>
  );
  */
    
  menuButtons.push(<Box flexGrow={1} />);

  menuButtons.push(
    <>
      <IconButton onClick={showMenu}>
        <AccountIcon fontSize="large" style={{ color: "#aaa" }} />
      </IconButton>
      <Menu
        id={menuAnchorEl ? "status-menu-popover" : undefined}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        onClose={hideMenu}
        keepMounted
        business={business}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
{/*
        <MenuItem onClick={() => dispatch(manageAccount(window.location.href))}>
          <ListItemText primary="My Account" />
        </MenuItem>
*/}
        <MenuItem onClick={() => dispatch(startNewConversation((conversation, error) => {
            window.open(window.location, "_self")
          }))}>
          <ListItemText primary="Reset" />
        </MenuItem>
        <MenuItem onClick={() => {
            const homeUrl = window.location.protocol + '//' + window.location.host
            // Always logout to home page
            dispatch(logout(homeUrl))
            // dispatch(logout(window.location.href))
          }}>
          <ListItemText primary={`Log Out ${user.userInfo.first_name || ""} ${user.userInfo.last_name || ""}`} />
        </MenuItem>
      </Menu>
    </>
  );

  const loc = useLocation();
  const history = useHistory();

  let showContent = false;
  if (loc.pathname != "/") {
    showContent = true;
  }

  const onHideContent = () => {
    history.push("/");
  };

  return (
    <Router>
      <Switch>
        <NavigationView
          main={main}
          menuButtons={menuButtons}
          showContent={showContent}
          onHideContent={onHideContent}
          setActiveView={setActiveView}
          activeView={activeView}
          menuAnchorEl={menuAnchorEl}
          showMenu={showMenu}
          business={business}
          hideMenu={hideMenu}
        >
          {contentRoutes.map((r, i) => (
            <Route key={i} path={r.path} exact={r.exact} component={r.main} />
          ))}
        </NavigationView>
      </Switch>
    </Router>
  );
};

export default NavigationController;
