import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { WSSTATE } from '../../store/service'

import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import Popover, {PopoverAnimationVertical} from '@material-ui/core/Popover'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import Reveal from 'react-reveal/Zoom'

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.default,
    
    paddingTop: theme.spacing.unit * 2,
  },
  content: {
    paddingLeft: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 2,    
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: '#ddd'
  },  
  title: {
    fontWeight: 800
  },
  subtitle: {  
  },
})

const ChatHeader = ({ classes, business, conversation, openChat, connectionState }) => {

  // When displayed inside popup, we need to get popup width, so that
  // the header div can have fixed position and width.
  // On mobile, we can just use 100%, but not popup is not full width.

  let headerWidth = '100%'
  
  let headerStyle = {    
//    display: 'block',
//    position: 'fixed',
//    top: '0'
    width: headerWidth
  }    

  let title = business.name
    title = conversation.data.parties.map(p => {
      if (p.agent) {
        let name = p.agent.first_name
        if (p.agent.last_name && p.agent.last_name !== undefined)
          name += " " + p.agent.last_name
        if (p.agent.is_service_user)
          name += "" //" (Bot)"
        else
          name += " (Agent)"
//          name += " (" + business.name + ")"
        return name
      }
      else
        return null
    }).reduce((p, n) => {
      if (p && n)
        return p + ", " + n
      else if (n)
        return n
      else
        return p
    })
    
    if (!title && conversation.data.queue)
      title = conversation.data.queue.name    
    
    if (!title || title.length <= 0)
      title = "You"
      
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const showMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setMenuAnchorEl(null);
  };


  return (
  
  <div className={classes.root} style={headerStyle}>  
    <Box container className={classes.content} display='flex' direction='row'>    
      <Box mr={1}>
        <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>      
      </Box>
      <Box flexGrow={1}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography className={classes.headerTitle} variant="body1" align="left" >
            { business.name }
          </Typography>

          { false && 
          <>
          <Typography className={classes.title} variant="body1" align="left" onClick={showMenu}>
            { business.name }
          </Typography>
          <ExpandMoreIcon />
          </>
          }
        </Box>
        <Typography className={classes.subtitle} variant="body2" align="left">
          { title }
        </Typography>  
      </Box>
    </Box>
    <Divider />
    <Menu
        id={menuAnchorEl ? 'chat-menu-popover' : undefined}
        open={ Boolean(menuAnchorEl) }
        anchorEl={menuAnchorEl}
        onClose={hideMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}        
      >
      <MenuItem onClick={ () => openChat(0) }>
        <AddIcon /><Box mr={1} />Start New Chat
      </MenuItem>
    </Menu>
    { connectionState != WSSTATE.Connected && 
    <Box style={{ position: 'absolute', top: '0', width: headerWidth, textAlign: 'center' }}>
      <Typography style={{ fontSize: '.9em', backgroundColor: '#f00', color: '#fff' }}>
        { connectionState }
      </Typography>
    </Box>
    }
  </div>
  )
}

export default withStyles(styles, { withTheme: true })(
  ChatHeader
  )