import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    maxHeight: "82%",
    marginTop: "105px",
    alignSelf: "flex-end",
    //overflowY: "scroll",
    //overflowX: "hidden",
    //paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {},
  },
}));

export default useStyles;
