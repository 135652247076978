import React, { useState, useEffect, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Route,
  Link as RouterLink,
  withRouter,
  useLocation,
} from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import { 
  getContentRecord, 
  fetchContentRecord,
  logSessionEvent,  
} from "../../store/service";

import Page from "./Page";

const styles = (theme) => ({
  root: {
    //    maxWidth: 480,
    //    display: 'flex',
    width: "100%",
    minHeight: "100vh",
  },
  content: {},
  divider: {
    width: "100%",
    height: theme.spacing.unit,
    //    backgroundColor: theme.palette.common.white,
    backgroundColor: "#eee",
  },
});

const ClusterPage = ({
  classes,
  history,
  openLink,
  isFetching,
  lastFetched,
  data,
  logEvent,
  fetchClusterDetail,
  clusterKey,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    fetchClusterDetail()
    if (lastFetched)
      logEvent('view', { 
          type: 'content',
          model: 'cluster',
          uid: data.uid, 
          url: window.location.href,
          title: `Cluster ${data.name}`,
      })          
  }, [clusterKey, lastFetched]);

  useEffect(() => {
    fetchClusterDetail(true);
  }, [pathname]);

  const renderText = (text, context) => {
    if (!text || !context) {
      return "";
    }

    return text.replace(/\{\{(.*?)\}\}/gi, (m, p1) => {
      const name = p1.trim();
      if (name in context) {
        return context[name];
      } else {
        return "";
      }
    });
  };

  const renderSections = (props) => {
    const { createSection, createContentBlock, divider, pageData } = props;

    const sectionsData = pageData.sections.map((s) => {
      let { items } = s;
      if (s.key == "hero") {
        if (lastFetched) {
          //s.background_images = data.images
          s.images = data.images
        }
      } else if (s.key == "gallery") {
        if (lastFetched && data.images) {
          items = data.images.map((i) => ({
            title: i.title, // || i.file_name,
            images: [i],
          }))
        }        
        else if (lastFetched && data.gallery) {
          items = data.gallery.map((i) => ({
            title: i.title,
            images: i.images,
          }))
        }
        else
          items = []
      } else if (s.key == "site-plan") {
        if (lastFetched && data.site_plan) {
          items = data.site_plan.map((i) => ({
            title: i.title,
            images: i.images,
          }))
        }
        else
          items = []
      }      

      return {
        ...s,
        title: renderText(s.title, data),
        subtitle: renderText(s.subtitle, data),
        body: renderText(s.body, data),
        items: items,
      };
    });

    const sections = sectionsData
      .map((sectionData, index) => createSection(sectionData, index))
      // Divider
      .reduce((prev, next) => [prev, divider, next]);

    return sections;
  };

  const renderHelmet = ({ pageData }) => {
    let title = "Cluster Detail";
    if (lastFetched) {
      title = data.name;
    }

    return (
      <title>
        {title} - {process.env.REACT_APP_TITLE_SUFFIX}
      </title>
    );
  };

  if (isFetching) {
    return (
      <Box style={{ width: "100%", height: "100vh", backgroundColor: "white" }}>
        <Typography>Loading</Typography>
      </Box>
    );
  } else {
    return (
      <Page
        pageKey="cluster-detail"
        renderSections={renderSections}
        renderHelmet={renderHelmet}
        clusterKey={clusterKey}
        doNotLogEvent={true}
      />
    );
  }
};

function clusterKeyFromProps(props) {
  let key = null;
  if (props.clusterKey) {
    key = props.clusterKey;
  } else if (props.match) {
    key = props.match.params.clusterKey;
  }
  return key;
}

const mapStateToProps = (state, ownProps) => {
  const key = clusterKeyFromProps(ownProps);
  return {
    ...getContentRecord(state, "cluster", key),
    clusterKey: key,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const key = clusterKeyFromProps(ownProps)
  // Fix search is null if there's hash  
  let urlParams = new URLSearchParams(ownProps.location.search)
  if (ownProps.location.hash && ownProps.location.hash.indexOf('?') != -1)  
    urlParams = new URLSearchParams(ownProps.location.hash.substring(
      ownProps.location.hash.indexOf('?')))
  
  return {
    fetchClusterDetail: (refresh) => {
      dispatch(fetchContentRecord("cluster", key, refresh))
    },
    logEvent: (eventName, eventParam) => {
      // Get session id param if any
      const sessionUid = urlParams.get("session")
      if (sessionUid)
        dispatch(logSessionEvent(sessionUid, eventName, eventParam))
    },   
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(ClusterPage)
  )
);
