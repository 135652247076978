import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { default as SlickSlider } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { red } from "@material-ui/core/colors";

import Card from "./Card";

const CardList = ({ classes, theme, content, openLink, sendMessage, containerWidth }) => {
  function onClick(button) {
    if (button.button_type && button.button_type == "callback") {
      const data = {
        ...button,
      };
      // alert(JSON.stringify(data, null, 2))
      sendMessage(JSON.stringify(data));
    } else if (button.link_to) {
      openLink(button.link_to);
    }
  }

  function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box className={classes.sliderPrevButton} onClick={onClick}>
        <ChevronLeftIcon className={classes.iconChevron} />
      </Box>
    );
  }

  function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box>
        <Box className={classes.gradationRight} />
        <Box className={classes.sliderNextButton} onClick={onClick}>
          <ChevronRightIcon className={classes.iconChevron} />
        </Box>
      </Box>
    );
  }

  // let slidesToShow = 1.4
  let slidesToShow = 1.0

  if (containerWidth > 200)
    slidesToShow = parseInt(containerWidth / 240)

  return (
    <Box mt={2} mb={2} style={{ position: "relative" }}>
      {content.card_list.length > 1 ? (
        <SlickSlider
          className="center"
          arrows={true}
          slidesToShow={slidesToShow}
          infinite={false}
          focusOnSelect={false}
          prevArrow={<SliderPrevArrow />}
          nextArrow={<SliderNextArrow />}
        >
          {content.card_list.map((card, index) => (
            <div>
              <Box className={classes.wrapperCard}>
                <Card
                  content={card}
                  sendMessage={sendMessage}
                  openLink={openLink}
                />
              </Box>
            </div>
          ))}
        </SlickSlider>
      ) : (
        content.card_list.map((card, index) => (
          <div>
            <Box style={{ width: "75%" }}>
              <Card
                content={card}
                sendMessage={sendMessage}
                openLink={openLink}
              />
            </Box>
          </div>
        ))
      )}
    </Box>
  );
};

const styles = (theme) => ({
  root: {},
  sliderPrevButton: {
    top: "50%",
    display: "block",
    position: "absolute",
    transform: "translate(0, -50%)",
    left: 0,
    zIndex: 1,
    width: "32px",
    height: "32px",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
  },
  wrapperCard: {
    paddingRight: theme.spacing(2),
    position: "relative",
  },
  gradationRight: {
    position: "absolute",
    right: 0,
    top: 0,
    // width: "80px",
    // background:
    //   "linear-gradient(90deg, rgba(66,66,66,0.13068977591036413) 28%, rgba(42,42,42,0.5536589635854341) 61%, rgba(48,48,48,1) 100%)",
    // background:
    //   "linear-gradient(90deg, rgba(250,250,250,0) 0%, (250, 250, 250,1) 100%)",

    height: "100%",
    marginBottom: "-20px",
  },
  sliderNextButton: {
    top: "50%",
    display: "block",
    position: "absolute",
    transform: "translate(0, -50%)",
    right: 0,
    zIndex: 1,
    width: "32px",
    height: "32px",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
  },
  iconChevron: {
    fontSize: "2rem",
    color: "white",
  },
});

export default withStyles(styles, { withTheme: true })(CardList);
