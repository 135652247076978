/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Input,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {
  ExpandMore as IconExpandMore,
  BusinessCenter as IconBusinessCenter,
  AttachMoney as IconSales,
} from "@material-ui/icons";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { COLORS } from "../../../config";
import {
  fetchContactRecord,
  getContactRecord,
  updateContactRecord,
} from "../../../store/service/reducers/contact";

import useStyles from "./styles";

function humanize(str) {
  if (str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  return str
}

const ContactDetail = () => {
  const [selectedContent, setSelectedContent] = useState("tab1");
  const [expanded, setExpanded] = useState(true);
  const [selectedActivity, setSelectedActivity] = useState(null)
  
  const [editMode, setEditMode] = useState(false);
  const [contactData, setContactData] = useState({
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
  });

  const contactDataTemp = {
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contactId } = useParams();
  const contactDetail = useSelector((state) =>
    getContactRecord(state, contactId)
  );
  const dateNow = moment();
  
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const activityParam = urlParams.get('activity')
    if (activityParam && activityParam != selectedActivity) {
      setSelectedActivity(activityParam)
    }
    else    
      setSelectedActivity(null)
      
    dispatch(fetchContactRecord(contactId, true));
  }, [contactId, location.search]);

  useEffect(() => {
    console.log(contactDetail);
    setEditMode(false);
  }, [contactId]);
  
  const handleScroll = (e) => {
    console.log("scroll");
  };
  
  const selectActivity = (item) => {
    if (selectedActivity == item.uid)
      setSelectedActivity(null)
    else
      setSelectedActivity(item.uid)
  }

  if (!contactDetail || (contactDetail && contactDetail.isFetching)) {
    return (
      <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
      />
    );
  }
  
  let selectedActivityEvents = []
  
  if (selectedActivity && contactDetail.lastFetched) {
    const activityData = contactDetail.activityHistory.data.find(a => a.uid == selectedActivity)
    
    if (activityData && activityData.session)
      selectedActivityEvents = contactDetail.visitHistory.data.filter(ev => ev.session.uid == activityData.session.uid)
  }

  const handleEdit = () => {
    if (editMode) {
      setEditMode(false);
      setContactData(contactDataTemp);
      return;
    }
    setEditMode(true);
    setContactData({
      ...contactData,
      first_name: contactDetail.data.first_name,
      last_name: contactDetail.data.last_name,
      mobile_no: contactDetail.data.mobile_no,
      email: contactDetail.data.email,
    });
  };

  const handleOnUpdateComplete = () => {
    setEditMode(false);
    setContactData(contactDataTemp);
  };

  const handleInputChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };
  
  const salesDeals = contactDetail.salesDeals.lastFetched ? contactDetail.salesDeals.data.records : []

  const activityHistory = contactDetail.activityHistory?.data?.filter(item => (
    item.activity_type.code === "meeting"
  ))

  return (
    <Box className={classes.containerContactDetail} onScroll={handleScroll}>
      {contactDetail.isUpdating && <LinearProgress />}
      <Box className={classes.wrapper}>
        <Box className={classes.wrapperHeroImg}>
          <Avatar alt="avatar" className={classes.heroImg}>
            <Typography>J</Typography>
          </Avatar>
        </Box>
        <Box className={classes.wrapperTitleName}>
          <Typography className={classes.contactName}>
            {contactDetail.data.first_name}
          </Typography>
          <Box className={classes.wrapperButtonEdit} onClick={handleEdit}>
            <Button
              className={classes.btnEdit}
              variant="contained"
              size="small"
              disableRipple
              onClick={() =>
                editMode &&
                dispatch(
                  updateContactRecord(contactId, {
                    ...contactDetail,
                    ...contactData,
                  }),
                  handleEdit()
                )
              }
            >
              {editMode ? "Save" : "Edit"}
            </Button>
            {editMode && (
              <Button
                className={classes.btnEdit}
                variant="contained"
                size="small"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Box>

        <Box className={classes.wrapperPersonalInfo}>
          <Accordion
            className={classes.accordionParent}
            expanded={editMode ? true : expanded}
          >
            <AccordionSummary
              expandIcon={<IconExpandMore />}
              onClick={() => setExpanded(!expanded)}
            >
              <Typography>Personal Info</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {editMode ? (
                <>
                  <Box className={classes.accordionItem}>
                    <Typography className={classes.accordionItemLabel}>
                      First Name
                    </Typography>
                    <Input
                      value={contactData.first_name}
                      name="first_name"
                      className={classes.inputEdit}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box className={classes.accordionItem}>
                    <Typography className={classes.accordionItemLabel}>
                      Mobile No
                    </Typography>
                    <Input
                      inputMode="numeric"
                      value={contactData.mobile_no}
                      name="mobile_no"
                      className={classes.inputEdit}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box className={classes.accordionItem}>
                    <Typography className={classes.accordionItemLabel}>
                      Email
                    </Typography>
                    <Input
                      value={contactData.email}
                      name="email"
                      inputMode="email"
                      type="email"
                      className={classes.inputEdit}
                      onChange={handleInputChange}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box className={classes.accordionItem}>
                    <Typography className={classes.accordionItemLabel}>
                      First Name
                    </Typography>
                    <Typography className={classes.accordionItemTitle}>
                      {contactDetail.data.first_name}
                    </Typography>
                  </Box>
                  <Box className={classes.accordionItem}>
                    <Typography className={classes.accordionItemLabel}>
                      Mobile No
                    </Typography>
                    <Typography className={classes.accordionItemTitle}>
                      {contactDetail.data.mobile_no
                        ? contactDetail.data.mobile_no
                        : "-"}
                    </Typography>
                  </Box>
                  <Box className={classes.accordionItem}>
                    <Typography className={classes.accordionItemLabel}>
                      Email
                    </Typography>
                    <Typography className={classes.accordionItemTitle}>
                      {contactDetail.data.email
                        ? contactDetail.data.email
                        : "-"}
                    </Typography>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>

        {!editMode && (
          <Box className={classes.wrapperTab}>
            <Box className={classes.wrapperTabs}>
              <Box
                className={classes.tabs}
                onClick={() => setSelectedContent("tab1")}
              >
                <Typography
                  style={
                    selectedContent === "tab1"
                      ? { color: COLORS.pink, fontWeight: "bold" }
                      : {}
                  }
                >
                  Activity History
                </Typography>
              </Box>
              <Box
                className={classes.tabs}
                onClick={() => setSelectedContent("tab2")}
              >
                <Typography
                  style={
                    selectedContent === "tab2"
                      ? { color: COLORS.pink, fontWeight: "bold" }
                      : {}
                  }
                >
                  Wish List
                  {salesDeals.length > 0 &&
                    <>&nbsp;({salesDeals.length})</>
                  }
                </Typography>
              </Box>
            </Box>
            
            <Box mt={2} />

            {selectedContent === "tab1" &&
            activityHistory ? (
              activityHistory.map((item, index) => (
              <div style={{ borderRadius: 4, overflow: "hidden" }}>
                <Accordion
                  className={classes.accordionParent}
                  expanded={selectedActivity == item.uid}
                >
                  <AccordionSummary
                    expandIcon={<IconExpandMore />}
                    onClick={() => selectActivity(item)}
                  >
                    <Box display='flex'>
                      <Box className={classes.wrapperIconActivitiesTab}>
                        <IconBusinessCenter fontSize="large" className={classes.icon} />
                      </Box>
                      <Box>
                        <Typography className={classes.recentActvTitle}>
                          {item.activity_type.name}
                          {item.custom_data && item.custom_data.activity_result &&
                            <>
                              &nbsp;/&nbsp;
                              {item.custom_data.activity_result}
                            </>
                          }                      
                        </Typography>
                        <Typography variant="body1">
                          { item.custom_data && item.custom_data.cancel_reason &&
                            <Typography variant="body2" noWrap={false}>
                              Reason: {item.custom_data.cancel_reason}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.notes &&
                            <Typography variant="body2" noWrap={false}>
                              Notes: {item.custom_data.notes}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.hold_reason &&
                            <Typography variant="body2" noWrap={false}>
                              Reason: {item.custom_data.hold_reason}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.next_action &&
                            <Typography variant="body2" noWrap={false}>
                              Next Action: {item.custom_data.next_action}
                            </Typography>
                          }
                          { item.custom_data && item.custom_data.visit_date &&
                            <Typography variant="body2" noWrap={false}>
                              Visit Date: {item.custom_data.visit_date}
                            </Typography>
                          }                    
                        </Typography>
                        <Typography className={classes.recentActvLabel}>
                          {moment(item.activity_time).format("DD MMM YYYY, HH:mm")}
                          {item.agent &&
                            <>&nbsp;with {item.agent.first_name} {item.agent.last_name}</>
                          }
                        </Typography>
                      </Box>
                    </Box>                    
                  </AccordionSummary>
                  <AccordionDetails className={classes.activityAccordionDetails}>
                  
                  { selectedActivityEvents &&
                    selectedActivityEvents.length > 0 ?           
                    selectedActivityEvents.map((item, index) => {
                      const event_data = (typeof item.event_data == "string") ? JSON.parse(item.event_data) : item.event_data;
                      
                      return (
                        <Box mb={1}>
                          <Box ml={0} display='flex' alignItems='center'>
                            <Box flexGrow={1}>
                              <Typography className={classes.eventTitle}>
                                {item.event_name == "view" ? "Lihat " + (event_data?.title || "Konten") : humanize(item.event_name)}
                              </Typography>
                            </Box>
                            <Box ml={1}>
                              <Typography className={classes.eventTime}>
                                {moment(item.created_at).format("HH:mm")}
                              </Typography>
                            </Box>
                          </Box>
                          {event_data && item.event_name != "view" && Object.keys(event_data).length > 0 &&
                            <Box>                            
                              <Typography className={classes.recentActvLabel}>                              
                                {Object.keys(event_data).filter(key => 
                                    !["model", "uid",].includes(key)
                                  ).map(key => (
                                  <>
                                    {humanize(key)}: {event_data[key]}
                                  </>
                                 )).reduce((p, n) => p ? 
                                  [...p, ", ", n] : [n], null)
                                }
                              </Typography>
                            </Box>
                          }
                        </Box>
                      );
                    })
                    :
                      <Box>
                        <Box ml={0} mb={1}>
                          <Typography className={classes.eventTitle}>
                            No captured events
                          </Typography>
                        </Box>
                      </Box>                    
                  }
                  </AccordionDetails>
                </Accordion>
                </div>
              ))
            ) : selectedContent === "tab1" ? (
              <Box className={classes.recentActivitiesItems}>
                <Box className={classes.wrapperIconActivitiesTab}>
                  <IconBusinessCenter fontSize="large" />
                </Box>
                <Box>
                  <Typography className={classes.recentActvTitle}>
                    No Data
                  </Typography>
                </Box>
              </Box>
            ) : null}

            {selectedContent === "tab2" &&
              salesDeals.length > 0 ? (
              salesDeals.map((item, index) => {

                return (
                  <Box display='flex' className={classes.recentActivitiesItems}>
                    <Box className={classes.wrapperIconActivitiesTab}>
                      <IconSales fontSize="large" className={classes.icon} />
                    </Box>
                    <Box>
                      <Typography className={classes.recentActvTitle}>
                        {item.name}
                      </Typography>
                      <Typography variant="body2" noWrap={false}>
                        {moment(item.created_at).format("DD MMM YYYY, HH:mm")}
                      </Typography>                      
                      <Box mt={1}/>
                      <Typography variant="body2" noWrap={false} component="span" className={classes.statusItem}>
                        {item.status.name}
                      </Typography>                      
                    </Box>
                  </Box>
                )
              })
            ) : selectedContent === "tab2" ? (
              <Box className={classes.recentActivitiesItems}>
                <Box className={classes.wrapperIconActivitiesTab}>
                    <IconBusinessCenter fontSize="large" className={classes.icon} />
                </Box>
                <Box>
                  <Typography className={classes.recentActvTitle}>
                    No Data
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContactDetail;
