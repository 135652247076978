import { combineReducers } from "redux";

import user from "./reducers/user";
import business from "./reducers/business";
import content from "./reducers/content";
import conversation from "./reducers/conversation";
import contact from "./reducers/contact";
import activityHistory from "./reducers/activityHistory";
import sales from "./reducers/sales";
import session from './reducers/session';

export const rootKey = "service";

export const reducer = combineReducers({
  business,
  user,
  contact,
  conversation,
  content,
  activityHistory,
  sales,
  session,
});
