import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { Page as AlphaPage } from "alpha-page";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {
  getContentRecord,
  fetchContentRecord,
  updateContentRecord,
  logSessionEvent,
} from "../../store/service";

const useStyles = makeStyles((theme) => ({
  root: {},
  contentPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  pageTitle: {
    fontWeight: 700,
  },
  sectionTitle: {
    fontWeight: 700,
  },
}));

const Page = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    pageKey,
    fetchPage,
    logEvent,
    savePage,
    isFetching,
    lastFetched,
    data,
    clusterKey,
  } = props;

  useEffect(() => {
    fetchPage();
  }, [pageKey]);
  
  useEffect(() => {
    if (lastFetched) {
      logEvent('view', { 
          type: 'content',
          model: data.model,
          uid: data.uid, 
          url: window.location.href,
          title: data.title || data.name
      })      
    }
  }, [pageKey, lastFetched])

  return (
    <div className={classes.root}>
      {lastFetched && (
        <AlphaPage
          {...props}
          isFetching={isFetching}
          lastFetched={lastFetched}
          clusterKey={clusterKey}
          data={data}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...getContentRecord(state, "page", ownProps.pageKey),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)
  
  return {
    fetchPage: () => {
      dispatch(fetchContentRecord("page", ownProps.pageKey));
    },
    logEvent: (eventName, eventParam) => {
      if (ownProps.doNotLogEvent)
        return
      // Get session id param if any
      const sessionUid = urlParams.get("session")
      if (sessionUid)
        dispatch(logSessionEvent(sessionUid, eventName, eventParam))
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Page)
);
