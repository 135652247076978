/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
  Button,
  TextField,
  Typography,
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import {
  getSalesRecord,
  runSalesAction,
} from "../../../store/service/reducers/sales";

import useStyles from "./styles";

const ActionForm = ({salesKey, action, open, onClose}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  
  const salesRecord = useSelector((state) =>
    getSalesRecord(state, salesKey)
  )
  
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  
  let actionFields = []
  if (action.to_status.key === "won")
    actionFields = [
      { name: "Nomor SO", code: "nomor_so", type: "text" },
    ]
  else if (action.to_status.key === "drop")
    actionFields = [
      { name: "Drop Reason", code: "drop_reason", type: "text" },
    ]

  const handleFormChange = (name) => (value) => {
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const cancelForm = () => {
    onClose()
  }

  const saveForm = () => {
    let errors = []
    actionFields.forEach(field => {
      const val = formValues[field.code]
      if (!val || !val.trim())
        errors.push(field.code)
    })
    
    setFormErrors(errors)
    if (errors.length > 0)
      return
      
    // If not set, clear field when moving to other state
    let data = {
      nomor_so: formValues.nomor_so || "",
      drop_reason: formValues.drop_reason || ""
    }
    
    if (salesRecord.data.nomor_so && !data.nomor_so) {
      if (!window.confirm("Perubahaan status ini akan menghapus Nomor SO yang sudah dimasukkan sebelumnya. Lanjut?")) {
        if (!actionFields.length)
          onClose()
        return
      }
    }
    if (salesRecord.data.drop_reason && !data.drop_reason) {
      if (!window.confirm("Perubahaan status ini akan menghapus Drop Reason yang sudah dimasukkan sebelumnya. Lanjut?")) {
        if (!actionFields.length)
          onClose()
        return
      }
    }    
    
    setIsSaving(true)    
    
    dispatch(runSalesAction(salesKey, action, data, (error) => {
      setIsSaving(false)
      if (error) {
        if (!actionFields.length)
          onClose()
      }
      else
        onClose()      
    }))
  }

  useEffect(() => {
    if (!isSaving && actionFields.length <= 0)
      setTimeout(saveForm, 100)
  }, [action])

  if (!actionFields.length && !isSaving)
    // Hide when there's no fields or in progress
    return null
  
  return (
    <Dialog open={open} onClose={() => !isSaving && onClose()}>
      {actionFields.length > 0 ?    
        <>
          <DialogContent>  
            <Box display='flex' flexDirection='column' width={320}>
              <Typography variant="h6">{action.name}</Typography>
              <Box mt={1} />
              {actionFields.map(field => (
                <TextField
                  label={field.name}
                  value={formValues[field.code]}
                  error={formErrors[field.code]}
                  type="text"
                  onChange={(event) => handleFormChange(field.code)(event.target.value)}
                  fullWidth
                  disabled={isSaving}
                  />                
              ))}
            </Box>
          </DialogContent>
          <DialogActions>      
            <Button onClick={saveForm} variant="contained" color="primary" disabled={isSaving}>
              {isSaving ?
                <CircularProgress size={24} />
              :
                "Continue"
              }
            </Button>
            <Button onClick={cancelForm} disabled={isSaving}>
              Cancel
            </Button>        
          </DialogActions>
        </>
      :
        <DialogContent>  
          <Box display='flex' justifyContent="center" alignItems="center" width={320} height={100}>
            {isSaving &&
              <CircularProgress />
            }
          </Box>
        </DialogContent>      
      }
    </Dialog>
  )
}

export default ActionForm