import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  loginWithEmailAndPassword,
  getUser,
} from "../../store/service";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  CircularProgress  
} from "@material-ui/core";

import { REGEXX } from "../../utilities";
import { IMAGES } from "../../config";
import { contentTheme } from "../../config/theme";

import useStyles from "./styles";

const Component = (props) => {
  const {loginWithEmailAndPassword, status, error} = props;

  const classes = useStyles();
  
  const [formData, setFormData] = useState({})
  const [formErrors, setFormErrors] = useState({})
  

  useEffect(() => {
    console.log("ini props dari login ", props);
  }, []);
  
  const onFormDataChanged = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value
    })
  }

  const onLogin = (e) => {
    e.preventDefault();
    if (!REGEXX.email.test(formData.email)) {
      setFormErrors({'email': 'Invalid email'})
      return
    }
    if (!formData.password) {
      setFormErrors({'password': 'Invalid password'})
      return
    }
    
    loginWithEmailAndPassword(formData.email, formData.password)
  };

  return (
    <ThemeProvider theme={contentTheme}>
      <Box className={classes.container}>
        <Paper className={classes.card}>
          {status ?
            <Box display='flex' alignItems='center' direction='column'>
              <Box mr={2}>
                <CircularProgress size='1.5rem' disableShrink={true} />                      
              </Box>
              <Box>
                <Typography>
                  { status }
                </Typography>
              </Box>
            </Box>
          :
          <>            
            <img src={IMAGES.sievaLogo} className={classes.imgLogo} />
            <Typography className={classes.desc}>
              Login to Sieva
            </Typography>
            
            { error &&
            <Typography className={classes.error}>
              Login failed! Please check your email and password.
            </Typography>
            }
            
            <form onSubmit={onLogin}>
              <FormControl className={classes.formWrapper}>
                {/* <InputLabel className={classes.labelInput}>
                  Email Address
                </InputLabel> */}
                <TextField
                  type="text"
                  name="email"
                  error={formErrors.email}
                  margin="normal"
                  onChange={onFormDataChanged("email")}
                  value={formData.email}
                  variant="outlined"
                  placeholder="Email"
                  className={classes.inputMaterial}
                />
                <TextField
                  type="password"
                  name="password"
                  error={formErrors.password}
                  margin="normal"
                  onChange={onFormDataChanged("password")}
                  value={formData.password}
                  variant="outlined"
                  placeholder="Password"
                  className={classes.inputMaterial}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.btnSubmit}
                >
                  <Typography>Login</Typography>
                </Button>
              </FormControl>
            </form>
          </>
          }
        </Paper>
      </Box>
    </ThemeProvider>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: getUser(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginWithEmailAndPassword: (email, password) => {
      dispatch(loginWithEmailAndPassword(email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
