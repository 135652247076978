import React from 'react'

import { connect } from 'react-redux'
import { getBusinessInfo, getUser, getConnectionState } from '../../store/service'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import { WSSTATE } from '../../store/service'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import Popover, {PopoverAnimationVertical} from '@material-ui/core/Popover'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import Reveal from 'react-reveal/Zoom'

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.default,
    
//    paddingTop: theme.spacing.unit * 2,
  },
  content: {
//    paddingLeft: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,    
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: '#ddd'
  },  
  title: {
    fontWeight: 800
  },
  subtitle: {  
  },
}))

const Header = ({ business, conversation, openChat, connectionState }) => {
  
  const classes = useStyles()
  const theme = useTheme()

  let headerWidth = '100%'
  
  let headerStyle = {    
    width: headerWidth
  }    

  let title = business.name
  
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const showMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
  
  <div className={classes.root} style={headerStyle}>  
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
      <Typography className={classes.title} variant="body1" align="left" onClick={showMenu}>
        Menu
      </Typography>
      <ExpandMoreIcon />
    </Box>        
  
    <Menu
        id={menuAnchorEl ? 'chat-menu-popover' : undefined}
        open={ Boolean(menuAnchorEl) }
        anchorEl={menuAnchorEl}
        onClose={hideMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}        
      >
      <MenuItem onClick={ () => openChat(0) }>
        <AddIcon /><Box mr={1} />Start New Chat
      </MenuItem>
    </Menu>
    { connectionState != WSSTATE.Connected && 
    <Box style={{ position: 'absolute', top: '0', width: headerWidth, textAlign: 'center' }}>
      <Typography style={{ fontSize: '.9em', backgroundColor: '#f00', color: '#fff' }}>
        { connectionState }
      </Typography>
    </Box>
    }
  </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {    
    business: getBusinessInfo(state, '0').data,
    user: getUser(state),
    connectionState: getConnectionState(state)
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {  
  return {    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)