import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Page } from ".";

const ContentPage = () => {
  const { contentKey } = useParams();
  
  useEffect(() => {
    
  }, [contentKey])
  
  return <Page pageKey={contentKey} />;
};

export default ContentPage;
