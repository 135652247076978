import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  card: {
    padding: "40px",
    paddingTop: "20px",
    borderRadius: "6px",
    marginTop: "50px",
    width: "320px",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
    backgroundColor: "white",
    elevation: 7,
  },
  desc: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "20px",
    fontWeight: 700
  },
  error: {
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
    color: "red"    
  },
  imgLogo: {
    display: "block",
    marginLeft: "auto",
    width: "125px",
    height: "42px",
    marginRight: "auto",
  },
  formWrapper: {
    width: "100%",
    marginTop: "20px",
  },
  inputMaterial: {
    width: "100%",
    color: "black",
    border: "1px solid #eaeaea",
    margin: 0,
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
    padding: "0",
    width: "100%",
  },
  labelInput: {
    color: "black",
  },
  inputField: {
    backgroundColor: "white",
    border: "1px solid #eaeaea",
    borderRadius: "5px",
    height: "30px",
    paddingLeft: "10px",
  },
  inputFieldError: {
    backgroundColor: "white",
    border: "1px solid red",
    borderRadius: "5px",
    height: "30px",
    paddingLeft: "10px",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  btnSubmit: {
    backgroundColor: "#ff4a5c",
    width: "100%",
//    borderRadius: "5px",
//    border: "0",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "20px",
  },
  invalidEmail: {
    textAlign: "center",
    color: "red",
    marginTop: "20px",
  },
}));

export default useStyles;
