import ls from "local-storage";
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// Data
import {
  login,
  getUser,
  authenticateWithToken,
  loginWithSSOToken,
  fetchContactInfo,
} from "./store/service";
import { getBusinessInfo, fetchBusinessInfo } from "./store/service";
import {
  fetchConversationCounts,
  getConnectionState,
  WSSTATE,
} from "./store/service";
import { wsConnect } from "./store/service";

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

const AppBase = (props) => {
  const { business, history, user, connectionState, params } = props;
  const {
    fetchBusinessInfo,
    fetchConversationCounts,
    login,
    authenticateWithToken,
    loginWithSSOToken,
    init,
    register,
  } = props;
  const { sso_token } = props.mainProps;

  const classes = useStyles(props);

  const getWSHost = () => {
    let host = process.env.REACT_APP_GOAPP_API_URL + "/ws/conversation/";
    host = host.replace("http://", "ws://");
    host = host.replace("https://", "wss://");
    host += "?";
    if (user.isLoggedIn) {
      host += "&token=" + user.authToken;
    }
    host += "&service_key=" + business.data.uid;
    return host;
  };

  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    window.addEventListener("online", (e) => {
      setIsOnline(true);
    });

    window.addEventListener("offline", (e) => {
      setIsOnline(false);
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (!user.isLoggedIn) {
      return;
    }

    if (connectionState == WSSTATE.Disconnected) {
      let timeout = 250;
      if (!isOnline) {
        timeout = 3000;
      }

      setTimeout(() => {
        //        alert(getWSHost())
        props.wsConnect(getWSHost());
      }, timeout);
      
    } else if (connectionState == WSSTATE.Connected) {
      // Refresh conversation list on re-connect.
      fetchConversationCounts(true);
    }
  }, [connectionState]);

  useEffect(() => {
    if (params.get("reset") == 1) {
      ls.remove("_goid");
      ls.remove("_userInfo");
      // Reload app
      window.open(process.env.REACT_APP_PUBLIC_URL, "_self");
      return;
    }

    const authToken = params.get("auth_token");

    // Check sso_token
    if (user.isAuthenticating || user.authError) {
      // Wait.
    } else if (sso_token && !user.isLoggedIn) {
      loginWithSSOToken(sso_token);
    }
    // Check token from callback URL
    else if (authToken && !user.isLoggedIn) {
      const authSuccess = params.get("auth_success");
      authenticateWithToken(authToken);

      params.delete("auth_token");
      params.delete("auth_success");

      // Update url
      // Note: Should use history.replace so that path with token not pushed
      // to history, but it doesn't work.
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    } else if (user.isAuthenticating) {
      // Just wait
//    } else if (user.userInfo && !user.isLoggedIn) {
    } else if (user.authToken && !user.isLoggedIn) {  
      if (!user.isAuthenticating) {
        // Try to login automatically
        login();
      }
    } else if (user.isLoggedIn && !business.lastFetched) {
      fetchBusinessInfo();
    } else if (user.isLoggedIn) {
      if (connectionState == WSSTATE.Idle) {
        props.wsConnect(getWSHost());
      }
    }
  });

  // Show page
  let loadingStatus = null;
  if (user.isAuthenticating) {
    loadingStatus = "Authenticating...";
  } else if (business.isFetching) {
    loadingStatus = "One second...";
  }

  const { mainComponent, mainProps } = props;

  const component = React.createElement(mainComponent, {
    ...mainProps,
    user,
    business,
    login,
    loadingStatus,
  });

  return <div className={classes.root}>{component}</div>;
};

const mapStateToProps = (state, ownProps) => {
  const urlParams = new URLSearchParams(ownProps.location.search);

  return {
    user: getUser(state),
    business: getBusinessInfo(state, "0"),
    params: urlParams,
    connectionState: getConnectionState(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  //  let callbackUrl = process.env.REACT_APP_PUBLIC_URL + ownProps.location.path
  const callbackUrl = window.location.href;
  //  alert(callbackUrl)

  return {
    fetchBusinessInfo: () => {
      dispatch(fetchBusinessInfo("0"));
    },
    login: (email = null) => {
      dispatch(login(callbackUrl, true, email));
    },
    authenticateWithToken: (authToken) => {
      dispatch(authenticateWithToken(authToken));
    },
    loginWithSSOToken: (sso_token) => {
      dispatch(loginWithSSOToken(sso_token));
    },
    wsConnect: (host) => {
      dispatch(wsConnect(host));
    },
    fetchConversationCounts: () => {
      dispatch(fetchConversationCounts());
    },
  };
};

const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBase));

export const withAppBase = (component, params = {}) => (props) => {
  return <App mainComponent={component} mainProps={props} {...params} />;
};

export default App;
