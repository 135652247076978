import ls from "local-storage";
import { combineReducers } from "redux";

import { updateObject, updateItemInArray, createReducer } from "./utility";

// Data structure

const salesRecordInitial = {
  key: null,
  isFetching: false,
  isUpdating: false,
  error: null,
  lastFetched: null,
  data: { status: null },
};

const salesListInitial = {
  isFetching: false,
  isFetchingLazyLoad: false,
  error: null,
  lastFetched: null,
  params: { size: 25, offset: 0 },
  plusSize: 25,
  data: {
    count: 0,
    records: [],
  },
};

const salesFieldsInitial = {
  isFetching: false,
  error: null,
  lastFetched: null,
  data: [],
};

// Actions
const ACTION = {
  requestSalesList: "requestSalesList",
  resetParams: "resetParams",
  requestSalesListLazyLoad: "requestSalesListLazyLoad",
  receiveSalesList: "receiveSalesList",
  errorRequestSalesList: "errorRequestSalesList",
  requestSalesRecord: "requestSalesRecord",
  receiveSalesRecord: "receiveSalesRecord",
  errorRequestSalesRecord: "errorRequestSalesRecord",
  updatingSalesRecord: "updatingSales",
  errorUpdatingSalesRecord: "errorUpdatingSales",

  requestSalesFields: "requestSalesFields",
  receiveSalesFields: "receiveSalesFields",
  errorRequestSalesFields: "errorRequestSalesFields",
};

export const getSalesList = (state) => {
  return state.service.sales.list;
};
export const getSalesFields = (state) => {
  return state.service.sales.fields;
};

export const getSalesRecord = (state, key) => {
  if (key in state.service.sales.records) {
    return state.service.sales.records[key];
  }
  return null;
};

// Async action creator

export const fetchSalesList = (
  refresh = false,
  params = false,
  invalidate = false,
  lazyload = false,
  search = false
) => {
  return (dispatch, getState) => {
    const res = getSalesList(getState());

    if (res.isFetching || (res.lastFetched && !refresh)) {
      return Promise.resolve();
    }

    if (invalidate) {
      dispatch(resetParams());
    }

    let url =
      process.env.REACT_APP_GOAPP_API_URL +
      `/project/service/issue/?project=SIEVA-DEAL&view=me&offset=${
        invalidate ? "0" : res.params.offset
      }&size=${res.params.size}&`;

    //    alert("Fetching: " + url)
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        url += `${key}=${value}&`;
      }
    }
    let authorization = {};
    const { user } = getState().service;
    if (user.isLoggedIn) {
      authorization = { Authorization: "jwt " + user.authToken };
    }

    const service_key = user.userInfo.business_uid;

    if (!res.data.records.length > 0) {
      dispatch(requestSalesList());
    }

    if (lazyload) {
      dispatch(requestSalesListLazyLoad());
    }

    console.log("ini params ", params);
    console.log("ini url ", url);
    console.log("ini service key ", service_key);
    console.log("ini search ", search);
    console.log("ini invalidate ", invalidate);

    return fetch(url, {
      headers: {
        "X-Service-Key": service_key,
        ...authorization,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let newDataRecord = res;
        console.log("ini json ", json);
        if (invalidate) {
          newDataRecord = json;
        } else {
          if (!search) {
            if (!json.records.length > 0) {
              dispatch(receiveSalesList(newDataRecord.data));
              return;
            } else {
              newDataRecord = newDataRecord.data
                ? {
                    ...res.data,
                    records: [...res.data.records, ...json.records],
                  }
                : json;
            }
          } else {
            newDataRecord = json;
          }
        }
        dispatch(receiveSalesList(newDataRecord));
      })
      .catch((error) => dispatch(errorRequestSalesList(error)));
  };
};

export const fetchSalesRecord = (key, refresh = false) => {
  return (dispatch, getState) => {
    const res = getSalesRecord(getState(), key);
    if (res && (res.isFetching || (res.lastFetched && !refresh))) {
      return Promise.resolve();
    }

    const url =
      process.env.REACT_APP_GOAPP_API_URL +
      "/project/service/issue/" +
      key +
      "/";

    //    alert("Fetching: " + url)
    let authorization = {};
    const { user } = getState().service;
    if (user.isLoggedIn) {
      authorization = { Authorization: "jwt " + user.authToken };
    }
    const service_key = user.userInfo.business_uid;

    dispatch(requestSalesRecord(key));
    return fetch(url, {
      headers: {
        "X-Service-Key": service_key,
        ...authorization,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveSalesRecord(key, json));
      })
      .catch((error) => dispatch(errorRequestSalesRecord(key, error)));
  };
};

export const updateSalesRecord = (key, data, onComplete) => {
  return (dispatch, getState) => {
    const url =
      process.env.REACT_APP_GOAPP_API_URL + `/project/service/issue/${key}/`;

    let authorization = {};
    const { user } = getState().service;
    if (user.isLoggedIn) {
      authorization = { Authorization: "jwt " + user.authToken };
    }

    const service_key = user.userInfo.business_uid;

    dispatch({
      type: ACTION.updatingSalesRecord,
      key,
    });

    return fetch(url, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "X-Service-Key": service_key,
        ...authorization,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json().then((json) => {
          if (response.ok) {
            dispatch(receiveSalesRecord(key, json));
          } else {
            let error = json
            if ('detail' in error)
              error = error.detail
            else if ('message' in error)
              error = error.message
            else
              error = JSON.stringify(json, null, 2)

            dispatch({
              type: ACTION.errorUpdatingSalesRecord,
              key,
              error,
            });
          }
          if (onComplete) {
            onComplete();
          }
        });
      })
      .catch((error) =>
        dispatch({
          type: ACTION.errorUpdatingSalesRecord,
          key,
          error,
        })
      );
  };
};

export const runSalesAction = (key, action, data, onComplete) => {
  return (dispatch, getState) => {
    const url =
      process.env.REACT_APP_GOAPP_API_URL + `/project/service/issue/${key}/run_action/`;

    let authorization = {};
    const { user } = getState().service;
    if (user.isLoggedIn) {
      authorization = { Authorization: "jwt " + user.authToken };
    }

    const service_key = user.userInfo.business_uid;
    
    const body = {
      action,
      ...data,
    }

    dispatch({
      type: ACTION.updatingSalesRecord,
      key,
    })
    
    return fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "X-Service-Key": service_key,
        ...authorization,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json().then((json) => {
          if (response.ok) {
            dispatch(receiveSalesRecord(key, json));
            if (onComplete)
              onComplete()
          } else {
            let error = json
            if ('detail' in error)
              error = error.detail
            else if ('message' in error)
              error = error.message
            else
              error = JSON.stringify(json, null, 2)

            dispatch({
              type: ACTION.errorUpdatingSalesRecord,
              key,
              error,
            })

            if (onComplete)
              onComplete(error)
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: ACTION.errorUpdatingSalesRecord,
          key,
          error,
        })
        if (onComplete)
          onComplete(error)
      })
  }
}

export const fetchSalesFields = (refresh = false) => {
  return (dispatch, getState) => {
    const res = getSalesFields(getState());

    if (res.isFetching || (res.lastFetched && !refresh)) {
      return Promise.resolve();
    }

    const url =
      process.env.REACT_APP_GOAPP_API_URL +
      "/sales/service/sales/sales_fields/";

    let authorization = {};
    const { user } = getState().service;
    if (user.isLoggedIn) {
      authorization = { Authorization: "jwt " + user.authToken };
    }

    const service_key = user.userInfo.business_uid;

    dispatch({
      type: ACTION.requestSalesFields,
    });

    return fetch(url, {
      headers: {
        "X-Service-Key": service_key,
        ...authorization,
      },
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveSalesFields(json)))
      .catch((error) =>
        dispatch({
          type: ACTION.errorRequestSalesFields,
          error,
        })
      );
  };
};

// Synchronous Action Creators

export const requestSalesList = () => ({
  type: ACTION.requestSalesList,
});
export const resetParams = () => ({
  type: ACTION.resetParams,
});
export const requestSalesListLazyLoad = () => ({
  type: ACTION.requestSalesListLazyLoad,
});

export const receiveSalesList = (json) => {
  const result = {
    count: json.count,
    offset: json.offset,
    total: json.total,
    size: json.size,
    records: json.records,
  };

  return {
    type: ACTION.receiveSalesList,
    result: result,
    receivedAt: Date.now(),
  };
};

export const errorRequestSalesList = (error) => ({
  type: ACTION.errorRequestSalesList,
  error,
});

export const requestSalesRecord = (key) => ({
  type: ACTION.requestSalesRecord,
  key,
});

export const receiveSalesRecord = (key, json) => {
  const result = json;

  //  alert("Sales Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveSalesRecord,
    key,
    result: result,
    receivedAt: Date.now(),
  };
};

export const errorRequestSalesRecord = (key, error) => ({
  type: ACTION.errorRequestSalesRecord,
  key,
  error,
});

export const receiveSalesFields = (json) => {
  //  alert("salesFields: " + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveSalesFields,
    result: json,
    receivedAt: Date.now(),
  };
};

// Reducers for Conversation

const salesRecordReducer = createReducer(salesRecordInitial, {
  [ACTION.requestSalesRecord]: (state, action) => {
    return {
      ...state,
      isFetching: true,
    };
  },
  [ACTION.receiveSalesRecord]: (state, action) => {
    //      alert(JSON.stringify(action.result, null, 2))

    return {
      ...state,
      isFetching: false,
      isUpdating: false,
      lastFetched: action.receivedAt,
      data: action.result,
    };
  },
  
  [ACTION.errorRequestSalesRecord]: (state, action) => {
    alert(action.error);
    return {
      ...state,
      isFetching: false,
      error: action.error,
    };
  },
  [ACTION.errorRequestSalesVisitHistory]: (state, action) => {
    alert(action.error);
    return {
      ...state,
      visitHistory: {
        ...state.visitHistory,
        isFetching: false,
        error: action.error,
      },
    };
  },
  [ACTION.errorRequestSalesActivityHistory]: (state, action) => {
    alert(action.error);
    return {
      ...state,
      activityHistory: {
        ...state.activityHistory,
        isFetching: false,
        error: action.error,
      },
    };
  },
  [ACTION.updatingSalesRecord]: (state, action) => {
    return {
      ...state,
      isUpdating: true,
    };
  },
  [ACTION.errorUpdatingSalesRecord]: (state, action) => {
    alert(action.error);
    return {
      ...state,
      isUpdating: false,
      error: action.error,
    };
  },
});

const salesListReducer = createReducer(salesListInitial, {
  [ACTION.requestSalesList]: (state, action) => {
    return {
      ...state,
      isFetching: true,
      params: { ...state.params, offset: 0 },
    };
  },
  [ACTION.resetParams]: (state, action) => {
    return {
      ...state,
      params: { size: 25, offset: 0 },
    };
  },
  [ACTION.requestSalesListLazyLoad]: (state, action) => {
    return {
      ...state,
      isFetchingLazyLoad: true,
    };
  },
  [ACTION.receiveSalesList]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      isFetchingLazyLoad: false,
      params: { ...state.params, offset: state.params.offset + state.plusSize },
      data: action.result,
      lastFetched: action.receivedAt,
    };
  },
  [ACTION.errorRequestSalesList]: (state, action) => {
    alert(action.error);
    return {
      ...state,
      isFetching: false,
      error: action.error,
    };
  },
  [ACTION.receiveSalesRecord]: (state, action) => {
    // Update data with latest from salesRecord
    let rec = action.result

    if (state.lastFetched) {
      let records = state.data.records.map(r => {
        if (r.uid == rec.uid)
          return rec
        else
          return r
      })

      return {
        ...state,
        data: {
          ...state.data,
          records
        }
      }
    }

    return state
  },

});

const salesFieldsReducer = createReducer(salesFieldsInitial, {
  [ACTION.requestSalesFields]: (state, action) => {
    return {
      ...state,
      isFetching: true,
    };
  },
  [ACTION.receiveSalesFields]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      data: action.result,
      lastFetched: action.receivedAt,
    };
  },
  [ACTION.errorRequestSalesFields]: (state, action) => {
    alert(action.error);
    return {
      ...state,
      isFetching: false,
      error: action.error,
    };
  },
});

function salesRecordByKeyUpdater(state, action) {
  const { key } = action;
  return {
    ...state,
    [key]: salesRecordReducer(state[key], action),
  };
}

const salesRecordByKeyReducer = createReducer(
  {},
  {
    [ACTION.requestSalesRecord]: salesRecordByKeyUpdater,
    [ACTION.updatingSalesRecord]: salesRecordByKeyUpdater,
    [ACTION.receiveSalesRecord]: salesRecordByKeyUpdater,
    [ACTION.errorRequestSalesRecord]: salesRecordByKeyUpdater,
    [ACTION.errorUpdatingSalesRecord]: salesRecordByKeyUpdater,
  }
);

// Combine list and records reducer

const salesReducer = combineReducers({
  list: salesListReducer,
  records: salesRecordByKeyReducer,
  fields: salesFieldsReducer,
});

export default salesReducer;
