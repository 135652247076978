import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import ReactMarkdown from "react-markdown";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

import ChatMessage from "./ChatMessage";
import CardWidget from "./widgets/Card";
import CardListWidget from "./widgets/CardList";
import FormInputWidget from "./widgets/FormInput";
import OptionMenuWidget from "./widgets/OptionMenu";

const widgetMapping = {
  card: CardWidget,
  card_list: CardListWidget,
  form_input: FormInputWidget,
  option_input: OptionMenuWidget,
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: "#ddd",
  },
  chatInfo: {
    color: "#999",
  },
  messageTop: {
    //    minHeight: '70vh',
    //    [theme.breakpoints.up('sm')]: {
    //      minHeight: '80vh'
    //    }

    minHeight: "50vh",
    [theme.breakpoints.up("sm")]: {
      //      minHeight: '30vh'
      minHeight: "40vh",
    },
  },
  inline: {
    display: "inline",
  },
  filesPreviewPaper: {
    display: "flex",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  displayNone: {
    display: "none",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  previewImageDiv: {
    width: "100%",
    borderColor: "#eeeeee",
    borderWidth: 1,
  },
  muted: {
    color: "#999",
  },
  myText: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
  },
  otherText: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    fontSize: theme.typography.body1.fontSize,
    // fontWeight: 600,
    fontWeight: 400,
  },
});

// const FilePreview = ({ classes, file }) => {
//   const [image, setImage] = useState(null);

//   useEffect(() => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       setImage(e.target.result);
//     };
//     reader.readAsDataURL(file);
//   }, file);

//   return (
//     <div className={classes.previewImageDiv}>
//       <img src={image} className={classes.previewImage} />
//     </div>
//   );
// };

const MessageItem = ({
  classes,
  theme,
  user,
  item,
  showInputComponent = false,
  openLink,
  sendMessage,
  businessAvatar,
  containerWidth,
}) => {
  /// console-.log('debug --- MessageItem')
  let sender = "";
  const messages = [];
  let date = "";
  let side = "left";
  let avatar = null;
  const alternate = false;

  if (item.isSending) {
    sender = "You";
    side = "right";
  } else if (user && user.isStaff) {
    if (
      item.data.sender.agent &&
      user &&
      user.isStaff &&
      item.data.sender.agent.uid == user.userInfo.uid
    ) {
      sender = "You";
      side = "right";
    } else if (item.data.sender.agent) {
      sender = item.data.sender.agent.first_name;
      if (item.data.sender.agent.last_name) {
        sender += " " + item.data.sender.agent.last_name;
      }

      sender = "";
      // 21 Apr 2021. Hide Sieva avatar
      // avatar = businessAvatar

      /*
      if (user.isStaff) {
        // Another Staff
        side = "right"
        alternate = true
      }
      */
    } else {
      sender = item.data.sender.contact.first_name;
      if (item.data.sender.contact.last_name) {
        sender += " " + item.data.sender.contact.last_name;
      }
      side = "left";
    }
  } else {
    if (item.data.sender.agent) {
      sender = item.data.sender.agent.first_name;
      if (item.data.sender.agent.last_name) {
        sender += " " + item.data.sender.agent.last_name;
      }
      side = "left";
    } else {
      sender = "You";
      side = "right";
    }
  }


  const animated = item.data.is_new ? true : false;
  let widget = null;

  const renderers = {
    paragraph: (props) => (
      <Typography
        gutterBottom
        className={side == "left" ? classes.otherText : classes.myText}
        variant="body2"
      >
        {" "}
        {props.children}{" "}
      </Typography>
    ),
  };

  let nextItem = item;
  while (nextItem) {
    const { content } = nextItem;

    if (content.text) {
      if (messages.length == 0) {
        messages.push(
          <div>
            {/*
            <Typography variant='body2' style={{ fontWeight: 800, opacity: 0.3 }}>{sender}</Typography>
            */}
            <ReactMarkdown source={content.text} renderers={renderers} />
          </div>
        );
      } else {
        messages.push(
          <ReactMarkdown source={content.text} renderers={renderers} />
        );
      }
    }

    if (
      nextItem.data.attachments &&
      nextItem.data.attachments !== undefined &&
      nextItem.data.attachments.length > 0
    ) {
      messages.push(
        ...nextItem.data.attachments.map((att) => (
          <img src={att.image_url} className={classes.previewImage} />
        ))
      );
    }

    if (!nextItem.next) {
      break;
    }
    nextItem = nextItem.next;
  }

  if (
    nextItem.content &&
    nextItem.content.componentType &&
    (!nextItem.content.isInputComponent || showInputComponent)
  ) {
    const widgetComponent = widgetMapping[nextItem.content.componentType];
    if (widgetComponent) {
      widget = React.createElement(widgetComponent, {
        content: nextItem.content,
        openLink,
        sendMessage,
        containerWidth,
      });
    }
  }

  if (nextItem.isSending) {
    date = "Sending...";
  } else if (nextItem.data.created_at) {
    date = moment(nextItem.data.created_at).fromNow(true);

    let showReadStatus = false;
    if (nextItem.data.sender) {
      if (user && user.isStaff) {
        //        if (user && nextItem.data.sender.agent && user.userInfo.uid == nextItem.data.sender.agent.uid)
        if (nextItem.data.sender.agent) {
          showReadStatus = true;
        }
      } else {
        if (nextItem.data.sender.contact) {
          showReadStatus = true;
        }
      }
    }

    if (showReadStatus) {
      if (nextItem.data.read_at !== undefined && nextItem.data.read_at) {
        date += " . read";
      } else {
        date += " . not read";
      }
    }
  }

  // const ChatMessageMemo = useMemo(()=> {
  //   return (
  //     <ChatMessage
  //       key={item.data.key}
  //       side={side}
  //       alternate={alternate}
  //       avatar={avatar}
  //       messages={messages}
  //       status={null}
  //       animated={animated}
  //     />
  //   )
  // }, [item.data.key])

  return (
    <div style={{ paddingTop: "0pt" }}>
      <ChatMessage
        key={item.data.key}
        side={side}
        alternate={alternate}
        avatar={avatar}
        messages={messages}
        status={null}
        animated={animated}
      />

      {widget}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(MessageItem);
