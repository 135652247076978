import React from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import useStyles from "./styles";

const Header = ({
  title = false,
  buttonRight = false,
  buttonRightIcon,
  buttonRightEvent,
  mainButton = false,
  mainButtonEvent,
  mainButtonTitle,
  searchInput = false,
  onChangeSearchInput,
  contentScroll,
}) => {
  const classes = useStyles();

  const checkPropsBool = () => {
    const propsArr = [
      title ? true : false,
      buttonRight,
      mainButton,
      searchInput,
    ];
    const checkPropsArr = propsArr.filter((data) => data === true);
    if (propsArr[1] === true && checkPropsArr.length === 1) {
      return true;
    }
    return;
  };

  return (
    <Box
      className={`${classes.container} ${
        checkPropsBool() ? classes.onlyIcon : ""
      }`}
    >
      <Box className={classes.wrapper}>
        <Box className={title ? classes.main : classes.mainFlexColumn}>
          {title && (
            <Typography
              variant="h4"
              component="h1"
              color="textPrimary"
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          {buttonRight && (
            <IconButton className={classes.btnRight} onClick={buttonRightEvent}>
              {buttonRightIcon}
            </IconButton>
          )}
        </Box>
        {mainButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.mainButton}
            fullWidth
            onClick={mainButtonEvent}
          >
            {mainButtonTitle}
          </Button>
        )}
      </Box>
      {searchInput && (
        <Box className={classes.wrapperSearchInput}>
          <input
            placeholder="Search"
            className={classes.searchInput}
            onChange={onChangeSearchInput}
          />
        </Box>
      )}
      {contentScroll && !checkPropsBool() && <Box className={classes.gradient} />}
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  buttonRight: PropTypes.bool,
  buttonRightIcon: PropTypes.elementType,
  buttonRightEvent: PropTypes.func,
  mainButton: PropTypes.bool,
  mainButtonEvent: PropTypes.func,
  searchInput: PropTypes.bool,
  onChangeSearchInput: PropTypes.func,
};

export default Header;
