import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../../config";

const useStyles = makeStyles((theme) => ({
  containerSalesDetail: {
    width: "100%",
    paddingTop: "25px",
    paddingBottom: "25px",
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  colorPrimary: {
    backgroundColor: "white",
  },
  barColorPrimary: {
    backgroundColor: "#1A76D1",
  },
  wrapperHeroImg: {},
  heroImg: {
    width: "100px",
    height: "100px",
    fontSize: "20px",
  },
  wrapperTitleName: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      position: "static",
    },
  },
  wrapperButtonEdit: {
    position: "absolute",
    top: "30%",
    right: 0,
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
  },
  btnEdit: {
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  wrapperPersonalInfo: {
    width: "100%",
  },
  salesName: {
    fontSize: "30px",
    margin: "20px 0",
    textAlign: "center",
  },
  accordionParent: {
    backgroundColor: COLORS.grey20,
    border: 0,
    width: "100%",
    "-webkit-box-shadow": "none",
    "-moz-box-shadow": "none",
    boxShadow: "none",
  },
  accordionItem: {
    marginBottom: "10px",
  },
  accordionItemLabel: {
    fontSize: "14px",
    color: COLORS.grey40,
  },
  inputEdit: {
    width: "100%",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  wrapperTab: {
    marginTop: "20px",
    width: "100%",
    alignSelf: "flex-start",
  },
  wrapperTabs: {
    display: "flex",
  },
  tabs: {
    fontSize: "16px",
    marginRight: "15px",
    cursor: "pointer",
  },
  recentActivitiesItems: {
    marginTop: "15px",
    padding: "15px",
    display: "flex",
    borderRadius: "7px",
    backgroundColor: COLORS.grey20,
  },
  wrapperIconActivitiesTab: {
    paddingRight: "20px",
  },
  recentActvTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  recentActvLabel: {
    color: COLORS.grey40,
    marginTop: "5px",
    fontSize: "14px",
  },
  statusItem: {
    borderRadius: "5px",
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`,
    backgroundColor: "lightgray",
    textColor: "#fff",
    fontWeight: 700
  },
  statusItem_wish_list: {
    backgroundColor: "yellow",
  },  
  statusItem_not_available: {
    backgroundColor: "lightgray",
  },  
  statusItem_won: {
    backgroundColor: "lightgreen",
  },  
  statusItem_drop: {
    backgroundColor: "red",
  },    
}));

export default useStyles;
