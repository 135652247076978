import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  fetchContactList,
  getContactList,
  å,
} from "../../store/service/reducers/contact";
import Header from "../../components/Header";

import useStyles from "./styles";

const ContactList = ({ setActiveView, activeView }) => {
  const [selected, setSelected] = useState("");
  const [scroll, setOnScroll] = useState(false);
  const [queryString, setQueryString] = useState({
    q: null,
    size: 25,
    offset: 0,
  });
  const contact = useSelector((state) => getContactList(state));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const dateNow = moment();

  const { isFetching, data, isFetchingLazyLoad } = contact;
  const { records } = data;

  useEffect(() => {
    const newQueryString = queryString;
    !newQueryString.q && delete newQueryString.q;
    dispatch(fetchContactList(true, null, true));
  }, [activeView]);

  useEffect(() => {
    console.log(contact);
  }, []);

  const handleSelected = (uid) => {
    setSelected((prevState) => (prevState === uid ? "" : uid));
    history.push(`/contact/${uid}`);
  };

  const onSearch = (e) => {
    setQueryString({ ...queryString, q: e.target.value, offset: 0 });
    const q = e.target.value;
    if (q.length >= 3) {
      setTimeout(() => {
        dispatch(fetchContactList(true, { q }, true, false, true));
      }, 500);
      return;
    }
    if (!q || q.length < 1) {
      const newQueryString = queryString;
      delete newQueryString.q;
      dispatch(fetchContactList(true, null, true, false, false));
      return;
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0) {
      if (scroll === false) {
        setOnScroll(true);
      }
    } else {
      setOnScroll(false);
    }
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (data && data.records.length > 0) {
        const newQueryString = queryString;
        !newQueryString.q && delete newQueryString.q;
        if (!isFetchingLazyLoad && records.length < data.total) {
          dispatch(fetchContactList(true, null, false, true));
        }
        return;
      }
    }
  };

  return (
    <>
      <Header
        title="My Customers"
        searchInput
        onChangeSearchInput={onSearch}
        contentScroll={scroll}
      />
      <Box className={classes.container} onScroll={handleScroll}>
        {!data || isFetching ? (
          <CircularProgress className={classes.loadingIndicator} />
        ) : (
          <List className={classes.contactList}>
            {records.map((item, index) => (
              <>
                <ListItem
                  key={index}
                  onClick={() => handleSelected(item.uid)}
                  className={`${classes.listItem} ${
                    selected === item.uid ? classes.listItemSelected : ""
                  }`}
                >
                  <ListItemText
                    className={`${
                      selected === item.uid ? classes.selectedListItem : ""
                    }`}
                    primary={
                      <>
                        <Box display="flex" alignItems="baseline">
                          <Box flexGrow={1}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 700 }}
                              noWrap={true}
                              color="textPrimary"
                            >
                              {item.first_name}
                              {"\n"}
                            </Typography>
                          </Box>
                          {item.last_activity_time &&
                          <Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              noWrap={true}
                            >
                              {moment(item.last_activity_time).fromNow(true)}
                            </Typography>
                          </Box>
                          }
                        </Box>
                      </>
                    }
                    secondary={
                      <Box className={classes.wrapperSecondaryListItem}>
                        <Box className={classes.secondaryListItem}>
                          {item.email && item.mobile_no ?
                            <Typography
                              variant="subtitle1"
                              color="textPrimary"
                              noWrap={false}
                            >
                              {item.mobile_no}
                              &nbsp;/&nbsp;
                              {item.email}
                            </Typography>
                          :
                            <>                    
                              {item.mobile_no &&
                                <Typography
                                  variant="subtitle1"
                                  color="textPrimary"
                                  noWrap={false}
                                >
                                  {item.mobile_no}
                                </Typography>
                              }
                              {item.email &&
                              <Typography
                                variant="subtitle1"
                                color="textPrimary"
                                noWrap={false}
                              >
                                {item.email}
                              </Typography>
                              }
                            </>
                          }
                        </Box>
                        { item.last_activity &&
                          <Box className={classes.secondaryListItem}>
                            <Typography variant="body2" noWrap={false}>
                              {item.last_activity.activity_type.name}
                              {item.last_activity.custom_data && item.last_activity.custom_data.activity_result &&
                                <>
                                  &nbsp;/&nbsp;
                                  {item.last_activity.custom_data.activity_result}
                                </>
                              }               
                            </Typography>                            
                          </Box>
                        }
                      </Box>
                    }
                  />
                </ListItem>
                <Divider light />
              </>
            ))}
          </List>
        )}
        {isFetchingLazyLoad && (
          <CircularProgress
            className={`${classes.loadingIndicator} ${classes.loadingIndicatorBottom}`}
          />
        )}
      </Box>
    </>
  );
};

export default ContactList;
